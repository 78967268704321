import { AccountService } from './../_services/account.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private accountService:AccountService){}

  canActivate(){
    if (this.accountService.isAdmin()){
      return true;
    }else{
      return false;
    }
  }
  
}
