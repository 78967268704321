<div class="row">
    <h1 style="color: white;">Gestione Sedi</h1>
    <button color="primary" mat-flat-button class="ml-4" (click)="crea()">Crea Sede</button>    
    <div class="col-lg-12">
        <mat-form-field class="filtro" style="margin-top: 2vh; margin-bottom: 2vh;">
            <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtra">
        </mat-form-field>
        <div class="mat-elevation-z20">

            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.id}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="nome">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> NOME </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.nome}}
                    </mat-cell>
                </ng-container>                  
                <ng-container matColumnDef="nomeEntita">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ENTITA' </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.entita.nome}}
                    </mat-cell>
                </ng-container>           
                <ng-container matColumnDef="aggancia">
                    <mat-header-cell *matHeaderCellDef>AGGANCIA</mat-header-cell>
                    <mat-cell *matCellDef="let row"><i class="material-icons" 
                            (click)="agganciaUnit(row)">link</i></mat-cell>
                </ng-container>                         
                <ng-container matColumnDef="modifica">
                    <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                    <mat-cell *matCellDef="let row"><i class="material-icons" 
                            (click)="openFileDialog(row)">edit_square</i></mat-cell>
                </ng-container>
                <ng-container matColumnDef="elimina">
                    <mat-header-cell *matHeaderCellDef>ELIMINA</mat-header-cell>
                    <mat-cell *matCellDef="let row"><i class="material-icons"  (click)="elimina(row)">delete</i></mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                </tr>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>