import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/_models/snackBarClass';

@Component({
  selector: 'app-snackbar-component',
  templateUrl: './snackbar-component.component.html',
  styleUrls: ['./snackbar-component.component.scss']
})
export class SnackbarComponentComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data:SnackBarClass) { }
  coloreSnack:string="";

  ngOnInit(): void {
    if (this.data.design==2){
      this.coloreSnack="red"
    }
  }

}
