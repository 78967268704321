<div   style="padding: 8px;margin: 15px auto;text-align: -webkit-center !important;">
    <div class="mat-elevation-z20" style="width: 30%;padding: 20px;background-color: #275e79;     text-align: -webkit-center !important;">
        <h2 style="color:#fff">Utente nethspot</h2>
        <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)">
            <p>
                <mat-form-field style="width: 30%;">
                    <mat-label >Username</mat-label>
                    <input matInput type="text" required formControlName="userNameNethspot">
                    <mat-hint *ngIf="userNameNethspot.touched && userNameNethspot.errors?.required" style="color: red;">
                        lo
                        username di Nethspot è obbligatorio</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field style="width: 30%;">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" required formControlName="passwordNethspot"
                        [type]="hidePassword1 ? 'password' : 'text'">
                    <mat-icon class="puntatore-mano" matSuffix (click)="hidePassword1 = !hidePassword1">{{hidePassword1
                        ?
                        'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-hint *ngIf="passwordNethspot.touched && passwordNethspot.errors?.required" style="color: red;">
                        La
                        password è
                        obbligatoria</mat-hint>
                </mat-form-field>
            </p>
            <p style="margin-top: 20px;">
                <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                    <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
                </button>
            </p>
        </form>
    </div>
    <!-- <div class="mat-elevation-z20" style="width: 50%;padding: 20px;">
        <h2>Foto profilo</h2>

    </div> -->

</div>