import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-error',
  templateUrl: './test-error.component.html',
  styleUrls: ['./test-error.component.scss']
})
export class TestErrorComponent implements OnInit {

  baseUrl: string = environment.apiUrl;
  validationsErrors:string[]=[];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  get404Error() {
    this.http.get(this.baseUrl + "buggy/not-found").subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
      })
  }

  get400Error() {
    this.http.get(this.baseUrl + "buggy/bad-request").subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
      })
  }

  get500Error() {
    this.http.get(this.baseUrl + "buggy/server-error").subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
      })
  }

  get401Error() {
    this.http.get(this.baseUrl + "buggy/auth").subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
      })
  }

  get400ValidationError() {
    this.http.post(this.baseUrl + "account/register",{}).subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
        this.validationsErrors=error;
      })
  }

}
