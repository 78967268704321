<h2 mat-dialog-title> Nuova sede </h2>
<mat-dialog-content style="width: 40vh!important;">
    <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)" id=f>
        <p>
            <mat-form-field>
                <mat-label style="color: black !important;">Nome</mat-label>
                <input style="color: black !important;" matInput id="nome" formControlName="nome">
                <mat-hint *ngIf="nome.touched && nome.errors?.required" style="color: red;">Il nome è
                    obbligatoria</mat-hint>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field class="border brd">
                <mat-label style="color: black !important;">Entità</mat-label>
                <mat-select name="idEntita" formControlName="idEntita" #sPadri>    
                    <mat-select-filter [placeholder]="'Cerca'" *ngIf="sPadri.focused"
                                [displayMember]="'description'" [array]="entita"
                                (filteredReturn)="fEntita =$event"></mat-select-filter>                        
                    <mat-option value=0>Nessuna entità padre selezionata</mat-option>
                    <mat-option [value]="entita.id" *ngFor="let entita of fEntita">
                        {{entita.nome}}</mat-option>
                </mat-select>                
            </mat-form-field>
        </p>
        
        <p style="margin-top: 20px;">
            <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
            </button>
        </p>
    </form>

</mat-dialog-content>