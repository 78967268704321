import { Sedi } from './../_models/sedi';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiMsg } from '../_models/apiMessage';
import { PatchMethod } from '../_models/patchMethod';

@Injectable({
    providedIn: 'root'
})
export class SediService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    GetSediList() {
        return this.http.get<Sedi[]>(this.baseUrl + "sedi");
    }

    GetSede(id: number) {
        return this.http.get<Sedi>(this.baseUrl + "sedi/" + id);
    }

    InsSede(sede: Sedi) {
        return this.http.post<Sedi>(this.baseUrl + "sedi/inserisci", sede);
    }

    UpdSede(id:number,campi:PatchMethod[]){
        return this.http.patch<ApiMsg>(this.baseUrl + "sedi/" + id,campi);
    }

    DelSede(id: number) {
        return this.http.delete<ApiMsg>(this.baseUrl + "sedi/elimina/" + id)
    }
}