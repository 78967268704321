import { AccountService } from 'src/app/_services/account.service';
import { User } from 'src/app/_models/user';
import { FunctionLocalStorage } from './../../_function/localstorage-function';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  panelAnagrafiche = false;
  panelGestioneAccount = false;
  panelAmministrazione=false;
  user:User;

  panelModuloMensa = false;
  panelModuloOrdini = false;
  panelModuloFirewall = false;

  panelSubModuloNethspot = false;
  panelSubModuloServer = false;

  constructor(private functionLS: FunctionLocalStorage,public accountService:AccountService) { }

  ngOnInit(): void {
    this.user = this.functionLS.getCurrentUser();    
  }

}
