import { User } from 'src/app/_models/user';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class FunctionLocalStorage{
    constructor(){}

    getCurrentUser(){        
        let lsUser = localStorage.getItem('user');
        return JSON.parse(lsUser);
        
         
    }

}