import { Utenti } from 'src/app/_models/utenti';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SiNoDialogComponent } from 'src/app/_dialog/si-no-dialog/si-no-dialog.component';
import { DialogClass } from 'src/app/_models/dialogClass';
import * as XLSX from 'xlsx';
import { UtentiService } from 'src/app/_services/utenti.service';
import { SnackBarClass } from 'src/app/_models/snackBarClass';
import { SnackbarComponentComponent } from 'src/app/_component/snackbar-component/snackbar-component.component';
import { ImportService } from 'src/app/_services/import.service';

export class ImportUtente {
  constructor(
    public Nome: string,
    public Cognome: string,
    public CodiceFiscale: string,
    public Email: string
  ) { }
}

interface Classi {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-import-utenti',
  templateUrl: './import-utenti.component.html',
  styleUrls: ['./import-utenti.component.scss']
})
export class ImportUtentiComponent implements OnInit {


  classi: Classi[] = []

  visDivButton: boolean = true;
  visDivGoogle: boolean = false;
  visDivFile: boolean = false;
  visDatiVoucher: boolean = false;

  importUtenti: ImportUtente[] = [];

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private utentiService: UtentiService, private importService:ImportService) { }

  ngOnInit(): void {
    this.classi = [
      { value: "1A", viewValue: "1A" },
      { value: "1B", viewValue: "1B" },
      { value: "2A", viewValue: "2A" },
      { value: "2B", viewValue: "2B" },
      { value: "3A", viewValue: "3A" }
    ]
  }

  creaUtenti() {
    let dialogData: DialogClass = new DialogClass("Creazione utenti wi-fi", "Creare i nuovi utenti selezionati?", true, true, true)
    let dialogRef = this.dialog.open(SiNoDialogComponent, { data: dialogData })
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          let utenti: Utenti[] = [];
          utenti.push(new Utenti(0, "", "Domenico", "Russo", true, 0, 1296, "", true, 0, 0, 0, 0, "expiration", 0, 513, "auth", "Russo Domenico", "prova@atleta.it", -1, 5,null,''))
          utenti.push(new Utenti(0, "", "Luca", "Ruzza", true, 0, 1296, "", true, 0, 0, 0, 0, "expiration", 0, 513, "auth", "Ruzza Luca", "luca@blu.it", -1, 5,null,''))
          utenti.push(new Utenti(0, "", "Giulia", "Birolini", true, 0, 1296, "", true, 0, 0, 0, 0, "expiration", 0, 513, "auth", "Birolini Giulia", "giulia@blu.it", -1, 5,null,''))
          utenti.push(new Utenti(0, "", "Axel", "Barzi", true, 0, 1296, "", true, 0, 0, 0, 0, "expiration", 0, 513, "auth", "Barzi Axel", "axel@blu-system.com", -1, 5,null,''))
          this.utentiService.registerMultiple(utenti).subscribe(
            response => {
              let snackBarData: SnackBarClass = new SnackBarClass("IMportazione avvenuta con successo", false);
              let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
            }

          )
        }
      })
  }

  importGoogle() {
    this.visDivGoogle = true;
    this.visDivButton = false;
  }

  importFile() {
    this.visDivFile = true;
    this.visDivButton = false;
  }

  onFileChange(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}

      this.lavoraDatiDaExcel(data);
      this.visDatiVoucher = true;
      //console.log(data); // Data will be logged in array format containing objects
    };
  }

  lavoraDatiDaExcel(data: unknown[]) {
    for (let val of data) {
      let ut: ImportUtente = this.tUnknown(val);
      this.pushUtente(ut);
    }
  }

  tUnknown(val: unknown) {
    const ut: ImportUtente = val as ImportUtente;
    return (ut);
  }

  pushUtente(ut: ImportUtente) {
    this.importUtenti.push(new ImportUtente(ut.Nome, ut.Cognome, ut.CodiceFiscale, ut.Email))
  }  

  loggingGoogle(){
    // this.importService.Prova().subscribe(
    //   response=>{
    //     console.log(response);
        
    //   }
    // )
  }

  
}
