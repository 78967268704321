<h4>Internal server error - refreshing the page will make the error disappear!</h4>
<ng-container *ngIf="error">
    <h5 class="text-danger">Error: {{error.message}} </h5>
    <p class="font-weight-bold">Note: if you are seeing this then Angular is probably not to blame</p>
    <p>What to do next?</p>
    <ol>
        <li>Open the chrome dev tools</li>
        <li>Inspect the network tab</li>
        <li>Check the failing request</li>
        <li>Examine the request URL - make sure it is correct</li>
        <li>Reproduce teh error in Postman - if we see the same response, the the issue is not with Angular</li>
    </ol>
    <p>Following is the stack trace - this is where you investigation should start!</p>
    <code class="mt-5" style="background-color: whitesmoke;"> {{ error.details }} </code>
</ng-container>