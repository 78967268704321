<div class="row">
    <h1 style="color: white;">Gestione Amministratori</h1>    
    <button mat-flat-button class="ml-4" color="primary" (click)="creaUtente()">Crea ruolo</button>
    <div class="col-lg-12">
        <mat-form-field class="filtro" style="margin-top: 2vh; margin-bottom: 2vh;">
            <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtra">
        </mat-form-field>
        <div class="mat-elevation-z20">

            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.id}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="userName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> USERNAME </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.userName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="nominativo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> NOMINATIVO </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.cognome }} {{ row.nome }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="roles">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> RUOLI </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.roles}}
                    </mat-cell>
                </ng-container>                
                <ng-container matColumnDef="sedi">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> SEDI </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                       <ng-container *ngFor="let ent of row.entita"> {{ent.nome}} </ng-container>
                    </mat-cell>
                </ng-container>                                
                <ng-container matColumnDef="opzioni">
                    <mat-header-cell *matHeaderCellDef>OPZIONI</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon >more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button mat-menu-item>
                              <span (click)="openFileDialog(row)">Modifica ruoli</span>
                            </button>
                            <button mat-menu-item>                              
                              <span (click)="openFileDialogSede(row)">Modifica sedi</span>
                            </button>
                            <button mat-menu-item>
                              <span (click)="eliminaUtente(row)">Elimina</span>
                            </button>
                          </mat-menu>
                    </mat-cell>
                </ng-container>
                
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                </tr>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>