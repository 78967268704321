import { UserSediDialogComponent } from './../../../_dialog/user-sedi-dialog/user-sedi-dialog.component';
import { SediService } from './../../../_services/sedi.service';
import { Entita } from './../../../_models/entita';
import { SnackbarComponentComponent } from '../../../_component/snackbar-component/snackbar-component.component';
import { SnackBarClass } from '../../../_models/snackBarClass';
import { ApiMsg } from '../../../_models/apiMessage';
import { AccountService } from 'src/app/_services/account.service';
import { RegisterUserDialogComponent } from '../../../_dialog/register-user-dialog/register-user-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RolesDialogComponent } from 'src/app/_dialog/roles-dialog/roles-dialog.component';
import { User } from 'src/app/_models/user';
import { AdminService } from 'src/app/_services/admin.service';
import { DialogClass } from 'src/app/_models/dialogClass';
import { SiNoDialogComponent } from 'src/app/_dialog/si-no-dialog/si-no-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sedi } from 'src/app/_models/sedi';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  users: Partial<User[]>;

  displayedColumns: string[] = ['id', 'userName','nominativo', 'roles','sedi','opzioni'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  rolesDialogRef: MatDialogRef<RolesDialogComponent>;
  apiMsg: ApiMsg;

  constructor(private adminService: AdminService, private dialog: MatDialog, private accountService: AccountService, private snackBar: MatSnackBar,private sediService:SediService) { }

  ngOnInit(): void {
    this.getUserWithRoles();
  }

  creaUtente() {
    let dialogRef = this.dialog.open(RegisterUserDialogComponent);
    dialogRef.afterClosed().subscribe(
      response => {
        if (response.event == "OK") {
          this.getUserWithRoles();
          let snackBarData: SnackBarClass = new SnackBarClass("Utente creato correttamente", false);
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })

        }
      }
    )
  }

  openFileDialog(user: User) {
    let dialogRef = this.dialog.open(RolesDialogComponent, { data: { user: user, roles: this.getRolesArray(user) } });
    dialogRef.afterClosed().subscribe(
      response => {
        if (response.event == "OK") {
          this.getUserWithRoles();
        }
      }
    )
  }

  openFileDialogSede(user:User){
    let tmpSedi:Sedi[];
    this.sediService.GetSediList().subscribe(
      response=>{
        tmpSedi=response;
        let dialogRef = this.dialog.open(UserSediDialogComponent,{data:{user:user, sedi:tmpSedi}});
        dialogRef.afterClosed().subscribe(
          response=>{
            if (response.event=="OK"){
              let snackBarData: SnackBarClass = new SnackBarClass("Sedi modificate correttamente correttamente", false);
              let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
              this.getUserWithRoles();
            }
          }
        )
      }
    )
   
  }

  eliminaUtente(user: User) {
    let dialogData: DialogClass = new DialogClass("Elimina Utente " + user.userName, "Sicuri di voler eliminare l'utente?", true, true, true)
    let dialogRef = this.dialog.open(SiNoDialogComponent, { data: dialogData })
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          this.accountService.delete(user.userName).subscribe(
            response => {
              this.apiMsg = response;
              let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false);
              let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
              this.getUserWithRoles();
            }
          )
        }
      }
    )



  }

  getUserWithRoles() {
    this.adminService.getUsersWithRoles().subscribe(
      response => {
        this.users = response;               
        this.dataSource = null;
        this.dataSource = new MatTableDataSource(this.users);
        this.users = this.users.sort((a, b) => (a.userName > b.userName ? -1 : 1));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;                
      }
    )
  }

  openRoles(user: User) {

  }

  private getRolesArray(user: User) {
    const roles = [];
    const userRoles = user.roles;
    const availableRoles: any[] = [
      { name: "SuperAdmin", value: "SuperAdmin" },
      { name: "Admin", value: "Admin" },
      { name: "Moderator", value: "Moderator" },
      { name: "Member", value: "Member" }
    ];

    availableRoles.forEach(role => {
      let isMatch = false;
      for (const userRole of userRoles) {
        if (role.name === userRole) {
          isMatch = true;
          role.checked = true
          roles.push(role);
          break;
        }
      }

      if (!isMatch) {
        role.checked = false,
          roles.push(role);
      }

    })

    return roles;
  }

  applicaFiltro(filtro: string) {
    this.dataSource.filter = filtro.trim().toLowerCase();
  }



}
