import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseUrl = environment.apiUrl;

  constructor(private http:HttpClient) { }

  getUsersWithRoles(){
    return this.http.get<Partial<User[]>>(this.baseUrl + "admin/users-with-roles");
  }

  updateUserRoles(userName:string,roles:string[]){
    return this.http.post(this.baseUrl + "admin/edit-roles/" + userName + "?roles=" + roles,{});
  }  

  // editUserEntita(username:string,entita:number[]){
  //   return this.http.post(this.baseUrl + "admin/edit-user-entita/" + username + "?entita=" + entita,{});
  // }

  editUserSedi(username:string,sedi:number[]){
    return this.http.post(this.baseUrl + "admin/edit-user-sedi/" + username + "?sedi=" + sedi,{});
  }
}
