export class Utenti{
    constructor(
        public id:number,
        public token:string,
        public nome:string,
        public cognome:string,
        public creaVoucher:boolean,
        public idVoucher:number,
        public hotspot_id:number,
        public code:string,
        public auto_login:boolean,
        public bandwidth_down:number,
        public bandwidth_up:number,
        public max_traffic:number,
        public max_time:number,
        public time:string,
        public duration:number,
        public expiration:number,
        public type:string,
        public user_name:string,
        public user_mail:string,
        public remain_use:number,
        public num_vouchers:number,
        public scadenza_voucher:Date,
        public googleOrgUnitPath:string
    ){}
    
}
