import { ExportService } from './../../../_services/export.service';
import { FiltroUtenti } from './../../../_models/_filters/filtroUtenti';
import { FormControl, FormGroup } from '@angular/forms';
import { DataGiornoDialogComponent } from './../../../_dialog/data-giorno-dialog/data-giorno-dialog.component';
import { RegisterUtenteDialogComponent } from '../../../_dialog/register-utente-dialog/register-utente-dialog.component';
import { Utenti } from '../../../_models/utenti';
import { UtentiService } from '../../../_services/utenti.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RolesDialogComponent } from 'src/app/_dialog/roles-dialog/roles-dialog.component';
import { ApiMsg } from 'src/app/_models/apiMessage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogClass } from 'src/app/_models/dialogClass';
import { SiNoDialogComponent } from 'src/app/_dialog/si-no-dialog/si-no-dialog.component';
import { SnackBarClass } from 'src/app/_models/snackBarClass';
import { SnackbarComponentComponent } from 'src/app/_component/snackbar-component/snackbar-component.component';
import { SelectionModel } from '@angular/cdk/collections';
import { SharedFunction } from 'src/app/_function/shared-functions';

export class CartelleFiltro{
  constructor(
    public testo:string    
  ){    
  }
}

@Component({
  selector: 'app-utente-management',
  templateUrl: './utente-management.component.html',
  styleUrls: ['./utente-management.component.scss']
})
export class UtenteManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  utenti: Utenti[];

  displayedColumns: string[] = ['select','nominativo', 'email', 'googleOrgUnitPath', 'dataScadenzaVoucher', 'modifica', 'elimina'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource<Utenti>();
  selection = new SelectionModel<Utenti>(true, []);

  rolesDialogRef: MatDialogRef<RolesDialogComponent>;
  apiMsg: ApiMsg;

  filtriForm:FormGroup;
  cartelleFiltro:CartelleFiltro[]=[];
  fCartelleFiltro;
  isCartellePopolate:boolean=false;

  constructor(private utentiService: UtentiService, private dialog: MatDialog, private snackBar: MatSnackBar,private sharedFunction:SharedFunction,private exportService:ExportService) { }

  ngOnInit(): void {   
    this.filtriForm = new FormGroup({
      'testo':new FormControl(''),
      'cartella':new FormControl(''),
      'statoVoucher':new FormControl(0)
    })
    this.getUtenti(this.filtriForm);    
  }    

  getUtenti(dati:FormGroup) {    
    let filtro:FiltroUtenti=dati.value;
    this.utentiService.getUtenti(filtro).subscribe(
      response => {

        this.utenti = response;


        // //Filtro
        // if (dati.value.testo != "") {
        //   this.utenti = this.utenti.filter(f => f.nome.toLowerCase().includes(dati.value.testo.toLowerCase())  
        //   || f.cognome.toLocaleLowerCase().includes(dati.value.testo.toLowerCase()) 
        //   || f.googleOrgUnitPath.toLocaleLowerCase().includes(dati.value.testo.toLowerCase()));
        // }
        // if (dati.value.cartella !=""){
        //   this.utenti =this.utenti.filter(f=>f.googleOrgUnitPath.toLowerCase()==dati.value.cartella.toLowerCase());
        // }
        // if(dati.value.statoVoucher !=0){  
        //   let oggi:Date=new Date();
        //   switch (dati.value.statoVoucher){
        //     case "1":{
        //       this.utenti = this.utenti.filter(f=>new Date(f.scadenza_voucher)>=oggi);
        //       break;
        //     }
        //     case "2":{
        //       let traDueSettimane:Date=new Date();
        //       traDueSettimane.setDate(oggi.getDate()+14)
        //       this.utenti = this.utenti.filter(f=>new Date(f.scadenza_voucher)>=oggi && new Date(f.scadenza_voucher)<=traDueSettimane);
        //       break;
        //     }
        //     case "-1":{
        //       this.utenti = this.utenti.filter(f=>new Date(f.scadenza_voucher)<oggi);
        //       break;
        //     }
        //   }
        // }

        this.dataSource = null;
        this.dataSource = new MatTableDataSource<Utenti>(this.utenti);
        this.utenti = this.utenti.sort((a, b) => (a.cognome > b.cognome ? -1 : 1));

        this.dataSource.sort = this.sort;

        this.dataSource.paginator = this.paginator;

        //Pulisco la selezione quando recupero di nuovo gli utenti
        this.selection = new SelectionModel<Utenti>(true, []);
        if (!this.isCartellePopolate){this.popolaCartelle()};
      }
    )
  }  

  popolaCartelle(){
    let arrayGruppi: string[] = [];
    this.utenti.forEach(function (account) {
      let tmpGruppo: string = account.googleOrgUnitPath;
      let presente = arrayGruppi.filter(f => f == tmpGruppo);
      if (presente.length == 0) {
        arrayGruppi.push(tmpGruppo);
      }
    })

    let tmpCartelle:CartelleFiltro[]=[];
    arrayGruppi.forEach(function(x){
      tmpCartelle.push(new CartelleFiltro(x));
    })
    this.cartelleFiltro=tmpCartelle;
    this.fCartelleFiltro = this.cartelleFiltro.slice();
    this.isCartellePopolate=true;
  }

  creaUtente() {
    let dialogRef = this.dialog.open(RegisterUtenteDialogComponent);
    dialogRef.afterClosed().subscribe(
      response => {
        if (response.event == "OK") {
          this.getUtenti(this.filtriForm);
          let snackBarData: SnackBarClass = new SnackBarClass("Utente creato correttamente", false);
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })

        }
      }
    )
  }

  eliminaUtente(utente: Utenti) {
    let dialogData: DialogClass = new DialogClass("Elimina Utente " + utente.cognome + ' ' + utente.nome, "Sicuri di voler eliminare l'utente?", true, true, true)
    let dialogRef = this.dialog.open(SiNoDialogComponent, { data: dialogData })
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          this.utentiService.delete(utente.id).subscribe(
            response => {
              this.apiMsg = response;
              let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false);
              let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
              this.getUtenti(this.filtriForm);
            }
          )
        }
      })
  }

  eliminaUtenti() {    
    let dialogData: DialogClass = new DialogClass("Eliminare tutti i " + this.selection.selected.length + " utenti selezionati?", "Sicuri di voler eliminare gli utenti?", true, true, true)
    let dialogRef = this.dialog.open(SiNoDialogComponent, { data: dialogData })
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          let utentiArray: Utenti[] = [];
          this.selection.selected.forEach(element => {
            utentiArray.push(element);
          });

          this.utentiService.deleteArray(utentiArray).subscribe(
            response => {
              this.apiMsg = response;
              let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false);
              let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
              this.getUtenti(this.filtriForm);
            }
          )
        }
      })
  }

  bloccaUtenti() {    
    let dialogData: DialogClass = new DialogClass("Bloccare tutti i " + this.selection.selected.length + " utenti selezionati?", "Sicuri di voler bloccare gli utenti?", true, true, true)
    let dialogRef = this.dialog.open(SiNoDialogComponent, { data: dialogData })
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          let tmpDataBlocco=new Date();
          tmpDataBlocco.setDate(tmpDataBlocco.getDate()-1);
          let tmpDay=this.sharedFunction.ritornaNumeroGiorniDifferenzaDaOggi(new Date(tmpDataBlocco));
          let utentiArray: Utenti[] = [];
          this.selection.selected.forEach(element => {
            //Metto l'expiration al giorno prima
            element.expiration=tmpDay;
            element.scadenza_voucher=tmpDataBlocco;
            utentiArray.push(element);
          });
          this.utentiService.bloccaArray(utentiArray).subscribe(
            response => {
              this.apiMsg = response;
              let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false);
              let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
              this.getUtenti(this.filtriForm);
            }
          )
        }
      });

  }

  cambiaScadenzaUtenti(){    
    let dialogRef = this.dialog.open(DataGiornoDialogComponent,{data:new Date()});
    dialogRef.afterClosed().subscribe(
      response => {        
        let tmpDay=this.sharedFunction.ritornaNumeroGiorniDifferenzaDaOggi(new Date( response.data));
        let utentiArray: Utenti[] = [];
        this.selection.selected.forEach(element => {
          //Metto l'expiration al giorno prima
          element.expiration=tmpDay;
          element.scadenza_voucher=response.data;
          utentiArray.push(element);
        });
        this.utentiService.bloccaArray(utentiArray).subscribe(
          response => {
            this.apiMsg = response;
            let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false);
            let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
            this.getUtenti(this.filtriForm);
          }
        )
      }
    )
  }

  modifica(utente: Utenti) {
    let dialogRef = this.dialog.open(RegisterUtenteDialogComponent,{ data : { utente: utente }});
    dialogRef.afterClosed().subscribe(
      response => {
        if (response.event == "OK") {
          this.getUtenti(this.filtriForm);
          let snackBarData: SnackBarClass = new SnackBarClass("Utente creato correttamente", false);
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })

        }
      }
    )
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Utenti): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  excel(dati:FormGroup){
    let filtro:FiltroUtenti = dati.value;
    this.exportService.UtentiExcel(filtro).subscribe(
      response=>{
        let blob=response;
        var link = document.createElement('a');
        link.href= window.URL.createObjectURL(blob);
        link.download="utenti.xlsx"
        link.click();
      }
    )
  }

}
