import { ConvertBytesPipe } from './_pipes/sizes_bytes_convert';
import { ConvertSecondsPipe } from './_pipes/time_seconds_convert';
import { UserManagementComponent } from './pages/_Shared/user-management/user-management.component';
import { RolesDialogComponent } from './_dialog/roles-dialog/roles-dialog.component';
import { SharedModule } from './_modules/shared.module';
import { SidebarComponent } from './navigation/sidebar/sidebar.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { HeaderComponent } from './navigation/header/header.component';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './_modules/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ServerErrorComponent } from './errors/server-error/server-error.component';
import { TestErrorComponent } from './errors/test-error/test-error.component';
import { LoadingInterceptor } from './_interceptors/loading.interceptor';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { LoginComponent } from './pages/_Shared/login/login.component';
import { RegisterUserDialogComponent } from './_dialog/register-user-dialog/register-user-dialog.component';
import { SiNoDialogComponent } from './_dialog/si-no-dialog/si-no-dialog.component';
import { SnackbarComponentComponent } from './_component/snackbar-component/snackbar-component.component';
import { HeaderUtentiComponent } from './navigation/header-utenti/header-utenti.component';
import { UtenteManagementComponent } from './pages/_Shared/utente-management/utente-management.component';
import { RegisterUtenteDialogComponent } from './_dialog/register-utente-dialog/register-utente-dialog.component';
import { EntitaComponent } from './pages/_Shared/entita/entita.component';
import { EntitaDialogComponent } from './_dialog/entita-dialog/entita-dialog.component';
import { ConfiguratorUserComponent } from './pages/_Shared/configurator-user/configurator-user.component';
import { SediDialogComponent } from './_dialog/sedi-dialog/sedi-dialog.component';
import { SediComponent } from './pages/_Shared/sedi/sedi.component';
import { UserSediDialogComponent } from './_dialog/user-sedi-dialog/user-sedi-dialog.component';
import { ImportUtentiComponent } from './pages/importazioni/import-utenti/import-utenti.component';
import { ImportGoogleComponent } from './pages/importazioni/import-google/import-google.component';

import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider
} from '@abacritt/angularx-social-login';
import { AddNethesisComponent } from './_dialog/add-nethesis/add-nethesis.component';
import { DataGiornoDialogComponent } from './_dialog/data-giorno-dialog/data-giorno-dialog.component';

const Componenti = [
  NotFoundComponent, ServerErrorComponent, TestErrorComponent,  LoginComponent,RolesDialogComponent,UserManagementComponent,
  RegisterUserDialogComponent,SiNoDialogComponent,SnackbarComponentComponent,HeaderUtentiComponent,UtenteManagementComponent,
  RegisterUtenteDialogComponent,EntitaComponent,EntitaDialogComponent,ConfiguratorUserComponent,
  SediDialogComponent,SediComponent,ImportUtentiComponent,ImportGoogleComponent  
]

const googleLoginOptions = {
  scope: 'profile email',
  plugin_name:'login' //you can use any name here
}; 

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    Componenti,
    UserSediDialogComponent,
    AddNethesisComponent,
    DataGiornoDialogComponent,    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    SharedModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    SocialLoginModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS,useClass:ErrorInterceptor,multi:true},
    {provide: HTTP_INTERCEPTORS,useClass:JwtInterceptor,multi:true},
    {provide: HTTP_INTERCEPTORS,useClass:LoadingInterceptor,multi:true},    
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '436874557714-kc8ionu1stdnkt7r2qg2ouka1ut9i26c.apps.googleusercontent.com',googleLoginOptions
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
