import { GoogleImportazioneNethspot } from './../_models/googleImportazioneNethspot';
import { Utenti } from './../_models/utenti';
import { GoogleAccount } from './../_models/googleAccount';
import { googleToken } from './../_models/googleToken';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ImportService {
    baseUrl = environment.apiUrl + "importazioni";

    constructor(private http: HttpClient) { }

    Prova(token:googleToken) {
        return this.http.post<boolean>(this.baseUrl,token);
    }

    GetUsersGoogle(){
        return this.http.get<GoogleAccount[]>(this.baseUrl + "/users-google")
    }

    GetUnitsGoogle(){
        return this.http.get<number>(this.baseUrl + "/units-google")
    }

    CreaUtentiSuperVise(googleImpNeth:GoogleImportazioneNethspot){
        return this.http.post<Utenti[]>(this.baseUrl + "/crea-utenti-sv",googleImpNeth);
    }
    
}