
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiMsg } from "../_models/apiMessage";
import { Cliente } from "../_models/clienti";

@Injectable({
    providedIn: 'root'
  })
  export class ClientiService {
    baseUrl = environment.apiUrl;
  
    constructor(private http: HttpClient) { }
  
    GetClientiList() {
      return this.http.get<Cliente[]>(this.baseUrl + "clienti");            
    }  

    GetCliente(id:number){      
      return this.http.get<Cliente>(this.baseUrl + "clienti/" + id);
    }

    InsCliente(cliente:Cliente){
        return this.http.post<Cliente>(this.baseUrl + "clienti/inserisci",cliente);
    }    
    
    DelCliente(id:number){
      return this.http.delete<ApiMsg>(this.baseUrl + "clienti/elimina/" + id);
    }

    editClienteLicenza(idCliente:number, licenze:number[]){
      return this.http.post(this.baseUrl + "clienti/edit-clienti-licenze/" + idCliente +"?licenze=" + licenze,{});
    }
  }