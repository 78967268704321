<h2 mat-dialog-title> Nuovo utente </h2>
<mat-dialog-content style="color: black !important; width: 40vh!important;">
    <form *ngIf="registerForm" [formGroup]="registerForm" (ngSubmit)="registerForm.valid && salva(registerForm)"
        id="registerForm">
        <p>
            <mat-form-field>
                <mat-label style="color: black !important;">Email</mat-label>
                <input style="color: black !important;" matInput id="userName" formControlName="userName">
                <mat-hint *ngIf="userName.touched && userName.errors?.required" style="color: red;">L'indirizzo mail è
                    obbligatorio</mat-hint>
                <mat-hint *ngIf="userName.touched && userName.errors?.email" style="color: red;">Inserire una mail
                    corretta</mat-hint>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field>
                <mat-label style="color: black !important;">Password</mat-label>
                <input style="color: black !important;" matInput type="password" required formControlName="password"
                    [type]="hidePassword1 ? 'password' : 'text'">
                <mat-icon class="puntatore-mano" matSuffix (click)="hidePassword1 = !hidePassword1">{{hidePassword1 ?
                    'visibility_off' : 'visibility'}}</mat-icon>
                <mat-hint *ngIf="password.touched && password.errors?.required" style="color: red;">La password è
                    obbligatoria</mat-hint>
                <mat-hint *ngIf="password.touched && password.errors?.minlength" style="color: red;">La password deve
                    essere minimo di 8 caratteri.</mat-hint>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field>
                <mat-label style="color: black !important;">Nome</mat-label>
                <input style="color: black !important;" matInput type="text" required formControlName="nome">
                <mat-hint *ngIf="nome.touched && nome.errors?.required" style="color: red;">Il nome è
                    obbligatorio</mat-hint>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field>
                <mat-label style="color: black !important;">Cognome</mat-label>
                <input style="color: black !important;" matInput type="text" required formControlName="cognome">
                <mat-hint *ngIf="cognome.touched && cognome.errors?.required" style="color: red;">Il nome è
                    obbligatorio</mat-hint>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field>
                <mat-label style="color: black !important;">Cliente</mat-label>
                <mat-select name="idCliente" formControlName="idCliente" #sClienti>    
                    <mat-select-filter [placeholder]="'Cerca'" *ngIf="sClienti.focused"
                                [displayMember]="'description'" [array]="clienti"
                                (filteredReturn)="fClienti =$event"></mat-select-filter>                        
                    <mat-option style="color: black !important;" value=0>Nessun cliente selezionato</mat-option>
                    <mat-option [value]="cliente.id" *ngFor="let cliente of fClienti">
                        {{cliente.ragioneSociale}}</mat-option>
                </mat-select>
                <mat-hint *ngIf="idCliente.touched && idCliente.errors?.min" style="color: red;">
                    Il firewall è obbligatorio</mat-hint>
            </mat-form-field>
        </p>
    </form>
    <mat-slide-toggle class="example-margin" [(ngModel)]="isSuperAdmin" [checked]="isSuperAdmin" [disabled]="superAdminDisabled">SuperAdmin</mat-slide-toggle><br>
    <mat-slide-toggle class="example-margin" [(ngModel)]="isAdmin" [checked]="isAdmin" [disabled]="adminDisabled">Admin</mat-slide-toggle><br>
    <mat-slide-toggle class="example-margin" [(ngModel)]="isModerator" [checked]="isModerator" [disabled]="moderatorDisabled">Moderator</mat-slide-toggle><br>
    <mat-slide-toggle class="example-margin" [(ngModel)]="isMember" [checked]="isMember">Member</mat-slide-toggle><br>

    <p style="margin-top: 20px;">
        <button mat-flat-button color="primary" id=btnSalva form="registerForm" [disabled]="!registerForm.valid"
            type="submit">
            <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
        </button>
    </p>
</mat-dialog-content>
<mat-dialog-actions>
    <!-- <button mat-button mat-dialog-close="OK">Ok</button>  -->
</mat-dialog-actions>