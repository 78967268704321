import { NethUnitsService } from 'src/app/_services/nethUnitsService';
import { Sedi } from './../../_models/sedi';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EntitaService } from './../../_services/entita.service';
import { SediService } from './../../_services/sedi.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FunctionLocalStorage } from 'src/app/_function/localstorage-function';
import { Entita } from 'src/app/_models/entita';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-sedi-dialog',
  templateUrl: './sedi-dialog.component.html',
  styleUrls: ['./sedi-dialog.component.scss']
})
export class SediDialogComponent implements OnInit {
  f:FormGroup;
  entita:Entita[];
  fEntita;

  constructor(private sediService:SediService,private entitaService:EntitaService,private dialgoRef:MatDialogRef<SediDialogComponent>,private functionLS:FunctionLocalStorage,private nethUnitsService:NethUnitsService) { }

  ngOnInit(): void {
    this.popolaCombo();
    this.f = new FormGroup({
      'nome': new FormControl('',[Validators.required]),
      'idEntita' : new FormControl((0),[Validators.required]),
      'idNethUnita':new FormControl((0),[Validators.required])
    })
  }

  get nome(){
    return this.f.get('nome');
  }

  get idNethUnita(){
    return this.f.get('idNethUnita');
  }

  get idEntita(){
    return this.f.get('idEntita');
  }

  popolaCombo(){
    this.entitaService.GetEntitaList().subscribe(
      response=>{
        this.entita = response;
        this.fEntita = this.entita.slice();
      }
    )
  }

  salva(dati:FormGroup){
    let sede:Sedi = dati.value;        
    this.sediService.InsSede(sede).subscribe(
      response=>{
        this.dialgoRef.close({event:"OK"});
      }
    )
  }

}
