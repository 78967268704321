import { ApiMsg } from './../_models/apiMessage';
import { environment } from './../../environments/environment';
import { User } from './../_models/user';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { PatchMethod } from '../_models/patchMethod';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseUrl = environment.apiUrl;
  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();
  
  private superAdmin = false;
  private admin = false;
  private moderator = false;
  private authenticated = false;

  constructor(private http: HttpClient) { }

  login(model: any) {
    return this.http.post(this.baseUrl + "account/login", model).pipe(
      map((response: User) => {
        const user = response;
        if (user) {
          this.setCurrentUser(user);
          this.authenticated = true;          
        }
      })
    )
  }

  register(model: any) {
    return this.http.post(this.baseUrl + "account/register", model).pipe(
      map((user: User) => {
        if (user) {
        }
      })
    )
  }

  delete(username:string){
    return this.http.delete<ApiMsg>(this.baseUrl + "account/delete/" + username)
  }

  setCurrentUser(user: User) {
    user.roles = [];
    const roles = this.getDecodedToken(user.token).role;
    Array.isArray(roles) ? user.roles = roles : user.roles.push(roles);

    localStorage.setItem('user', JSON.stringify(user));
    this.currentUserSource.next(user);
    this.authenticated = true;

    //Setto se sono superAdmin e/o admin e/o moderator
    if (user.roles.includes("SuperAdmin")) {
      this.superAdmin = true;
    }else{
      this.superAdmin=false;
    };
    if (user.roles.includes("Admin")) {
      this.admin = true;
    }else{
      this.admin=false;
    };
    if (user.roles.includes("Moderator")) {
      this.moderator = true;
    }else{
      this.moderator=false;
    }
  }

  logout() {
    localStorage.removeItem('user');
    this.currentUserSource.next(null);
    this.authenticated = false;
  }

  getDecodedToken(token) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  isSuperAdmin(){
    return this.superAdmin;
  }

  isAdmin() {
    return this.admin;
  }

  isModerator() {
    return this.moderator;
  }

  //Funzione messa per determinare se un utente è loggato o meno
  isAuth() {
    //Recupero il token dalla localstorage
    let tmpUser= JSON.parse(localStorage.getItem("user"));
    
    //Se il token è scaduto sloggo l'utente
    const helper = new JwtHelperService();  
    if (helper.isTokenExpired(tmpUser.token)) {
      this.logout();
    }

    const decodedToken = helper.decodeToken(tmpUser.token);
    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp);
    
    return this.authenticated;

  }

  editUsernethspot(username:string,campi:PatchMethod[]){
    return this.http.patch<ApiMsg>(this.baseUrl + "account/edit-user-nethspot/" + username,campi);
  }
}
