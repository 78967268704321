import { Utenti } from '../../../_models/utenti';
import { UtentiService } from '../../../_services/utenti.service';
import { User } from 'src/app/_models/user';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/_services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/_models/snackBarClass';
import { SnackbarComponentComponent } from 'src/app/_component/snackbar-component/snackbar-component.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  f: FormGroup;
  fUtente:FormGroup;

  constructor(public accountService: AccountService, private router: Router,private utentiService:UtentiService,private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    //Se l'utente è loggato indirizzo alla dashboard
    this.accountService.currentUser$.subscribe(
      response => {
        if (response as User) {
          this.indirizzaDashboardManage();
        }else{
          this.utentiService.currentUtente$.subscribe(
            response=>{
              if (response as Utenti){
                this.indirizzaDashboardUtente();
              }
            }
          )
        }
      }
    )

    this.f = new FormGroup({
      'userName': new FormControl('', [Validators.required, Validators.email]),
      'password': new FormControl('', [Validators.required])
    })
    this.fUtente = new FormGroup({
      'voucher':new FormControl('',[Validators.required])
    })
  }

  get userName() {
    return this.f.get('userName');
  }

  get password() {
    return this.f.get('password');
  }

  get voucher(){
    return this.fUtente.get('voucher');
  }

  login() {
    this.accountService.login(this.f.value).subscribe(
      response => {       
        this.indirizzaDashboardManage();
      },error=>{
        let snackBarData: SnackBarClass = new SnackBarClass("Credenziali errate.", false);
        snackBarData.design=2;
        let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 5000 })
        
      }
    )
  }

  loginUtente(){
    this.utentiService.login(this.fUtente.get("voucher").value).subscribe(
      response=>{
        this.indirizzaDashboardUtente();
        
      }
    )
    
  }

  indirizzaDashboardUtente(){
    this.router.navigateByUrl('/utente');
  }

  indirizzaDashboardManage() {
    if (this.accountService.isSuperAdmin()) {
      this.router.navigateByUrl('/superadmin');
    } else {
      if (this.accountService.isAdmin()) {
        this.router.navigateByUrl('/admin');
      } else {
        if (this.accountService.isModerator()) {
          this.router.navigateByUrl('/moderator');
        } else {
          this.router.navigateByUrl('/user');
        }
      }

    }
  }




}
