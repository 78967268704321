import { Router } from '@angular/router';
import { UtentiService } from './../../_services/utenti.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header-utenti',
  templateUrl: './header-utenti.component.html',
  styleUrls: ['./header-utenti.component.scss']
})
export class HeaderUtentiComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  model:any={}

  constructor(public utentiService:UtentiService,private router:Router) { }

  ngOnInit(): void {
  }

  logout(){
    this.utentiService.logout();
    this.router.navigateByUrl('/')
  }

  toggleSideBar(){
    this.toggleSideBarForMe.emit();

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 200);
  }

}
