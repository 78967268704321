<div class="col-lg-12" *ngIf="visDivButton">
    <button mat-flat-button color="primary" (click)="importGoogle()">Importa da Google</button>
</div>
<br>
<div class="col-lg-12" *ngIf="visDivButton">
    <button mat-flat-button color="primary" (click)="importFile()">Importa da file</button>
</div>

<div class="col-lg-12" *ngIf="visDivFile">
    <h1>Importazione utenti da file</h1>
    <div class="col-12">
        <div class="text-center">
            <br><br>Seleziona il file excel dal dispositivo<br>
            <input class="my-3" style="text-align-last: center;" type="file" ng2FileSelect [uploader]="uploader"
                multiple (change)="onFileChange($event)" />
        </div>
        <div>
            <p *ngFor="let ut of importUtenti"> <input type="checkbox"> {{ ut.Nome }} - {{ ut.Cognome }} - {{
                ut.CodiceFiscale }} - {{ ut.Email }}
                <select matNativeControl name="classe">
                    <option value="" selected></option>
                    <option *ngFor="let cl of classi" [value]="cl.value">
                        {{cl.viewValue}}
                    </option>
                </select>
            </p>
        </div>
        <div *ngIf="visDatiVoucher">
            <mat-slide-toggle class="my-3" id="auto_login">Autologin</mat-slide-toggle><br><br>
            <h3 style="color: #0cb5f7;">Seleziona periodo di validità</h3>
            <select matNativeControl name="periodo">
                <option value="" selected></option>
                <option value="Anno">
                    Anno scolastico 2022/2023
                </option>
            </select>
        </div>
        <button mat-flat-button color="primary" (click)="creaUtenti()">Crea utenti</button>
    </div>
</div>

<div class="col-lg-23" *ngIf="visDivGoogle">
    <h1 style="color: white;">Importazione da Google</h1>
    <button mat-flat-button color="primary" (click)="loggingGoogle()">Loggati con Google</button>
</div>