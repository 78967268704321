import { SediDialogComponent } from './../../../_dialog/sedi-dialog/sedi-dialog.component';
import { SediService } from './../../../_services/sedi.service';
import { Sedi } from './../../../_models/sedi';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiMsg } from 'src/app/_models/apiMessage';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/_models/snackBarClass';
import { SnackbarComponentComponent } from 'src/app/_component/snackbar-component/snackbar-component.component';
import { DialogClass } from 'src/app/_models/dialogClass';
import { SiNoDialogComponent } from 'src/app/_dialog/si-no-dialog/si-no-dialog.component';
import { AddNethesisComponent } from 'src/app/_dialog/add-nethesis/add-nethesis.component';

@Component({
  selector: 'app-sedi',
  templateUrl: './sedi.component.html',
  styleUrls: ['./sedi.component.scss']
})
export class SediComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  sedi:Sedi[];

  displayedColumns: string[] = ['id', 'nome','nomeEntita','aggancia','modifica', 'elimina'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  apiMsg: ApiMsg;

  constructor(private sediService:SediService,private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getSedi();
  }

  getSedi(){
    this.sediService.GetSediList().subscribe(
      response=>{
        this.sedi=response;      
        
        this.dataSource = null;
        this.dataSource = new MatTableDataSource(this.sedi);
        this.sedi = this.sedi.sort((a, b) => (a.nome > b.nome ? -1 : 1));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;          
        
      }
    )
  }

  applicaFiltro(filtro: string) {
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  elimina(sede:Sedi){
    let dialogData: DialogClass = new DialogClass("Elimina sede " + sede.nome, "Sicuri di voler eliminare la sede?", true, true, true)
    let dialogRef = this.dialog.open(SiNoDialogComponent, { data: dialogData })
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          this.sediService.DelSede(sede.id).subscribe(
            response => {
              this.apiMsg = response;
              let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false);
              let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
              this.getSedi();
            }
          )
        }
      }
    )
  }

  crea(){
    let dialogRef = this.dialog.open(SediDialogComponent);
    dialogRef.afterClosed().subscribe(
      response => {
        if (response.event == "OK") {
          this.getSedi();
          let snackBarData: SnackBarClass = new SnackBarClass("Sede creata correttamente", false);
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })

        }
      }
    )
  }

  agganciaUnit(sede:Sedi){
    let dialogref = this.dialog.open(AddNethesisComponent,{ data : { sede: sede }});
    dialogref.afterClosed().subscribe(
      response=>{
        if (response.event=="OK"){
          this.getSedi();
          let snackBarData: SnackBarClass = new SnackBarClass("Sede agganciata correttamente", false);
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
        }
      }
    )
  }

}
