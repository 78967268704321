<h2 mat-dialog-title> Nuova entità </h2>
<mat-dialog-content style="width: 40vh!important;">
    <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)" id=f>
        <p>
            <mat-form-field>
                <mat-label style="color: black !important;">Nome</mat-label>
                <input style="color: black !important;" matInput id="name" formControlName="name">
                <mat-hint *ngIf="name.touched && name.errors?.required" style="color: red;">Il nome è
                    obbligatoria</mat-hint>
            </mat-form-field>
        </p>
        <ng-container>
            <p>
                <mat-slide-toggle id="creaHotspot" formControlName="creaHotspot">Crea hotspot</mat-slide-toggle><br>
            </p>

            <p>
                <mat-form-field>
                    <mat-label style="color: black !important;">Descrizione</mat-label>
                    <input style="color: black !important;" matInput id="description" formControlName="description">
                    <mat-hint *ngIf="description.touched && description.errors?.required" style="color: red;">La
                        descrizione è obbligatoria</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label style="color: black !important;">Ragione Sociale</mat-label>
                    <input style="color: black !important;" matInput id="business_name" formControlName="business_name">
                    <mat-hint *ngIf="business_name.touched && business_name.errors?.required" style="color: red;">La
                        ragione sociale è obbligatoria</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label style="color: black !important;">P.IVA/C.F.</mat-label>
                    <input style="color: black !important;" matInput id="business_vat" formControlName="business_vat">
                    <mat-hint *ngIf="business_vat.touched && business_vat.errors?.required" style="color: red;">La
                        P.IVA/C.F. è obbligatoria</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label style="color: black !important;">Indirizzo</mat-label>
                    <input style="color: black !important;" matInput id="business_address"
                        formControlName="business_address">
                    <mat-hint *ngIf="business_address.touched && business_address.errors?.required" style="color: red;">
                        L'indirizzo è obbligatorio</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label style="color: black !important;">Email del cliente</mat-label>
                    <input style="color: black !important;" matInput id="business_email"
                        formControlName="business_email">
                    <mat-hint *ngIf="business_email.touched && business_email.errors?.required" style="color: red;">La
                        mail del cliente è obbligatoria</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label style="color: black !important;">Nome e cognome del DPO (opzionale)</mat-label>
                    <input style="color: black !important;" matInput id="business_dpo" formControlName="business_dpo">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label style="color: black !important;">Indirizzo email del DPO (opzionale)</mat-label>
                    <input style="color: black !important;" matInput id="business_dpo_mail"
                        formControlName="business_dpo_mail">
                </mat-form-field>
            </p>
        </ng-container>

        <p style="margin-top: 20px;">
            <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
            </button>
        </p>
    </form>

</mat-dialog-content>