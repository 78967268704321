<h2 mat-dialog-title> Indica data </h2>
<mat-dialog-content style="width: 40vh!important;">
    <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)" id=f>        
        <mat-form-field appearance="fill" class="example-form-field">
          <mat-label style="color: black;">Choose a date</mat-label>
          <input style="color: black;" matInput [matDatepicker]="datepicker" formControlName="dataGiorno">
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
        <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
            <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
        </button>
    </form>
    <p style="margin-top: 20px;">
        
    </p>
</mat-dialog-content>