import { GoogleAccount } from './googleAccount';
export class GoogleImportazioneNethspot{
    constructor(
        public googleAccounts:GoogleAccount[],
        public parametriNethspot:DatiGoogleIMportazioneNethspot
    ){}
}

export class DatiGoogleIMportazioneNethspot{
    constructor(
        public duration:number,
        public expiration:number,
        public auto_login:boolean,
        public hotspot_id:number,
        public bandwidth_down:number,
        public bandwidth_up:number,
        public max_traffic:number,
        public max_time:number,
        public time:string,
        public type:string,
        public num_vouchers:number,
        public scadenza_voucher:Date
    ){}
}