

<mat-horizontal-stepper style="background-color: #275e79; margin-top:1%;" [linear]="isLinear" #stepper>

  <mat-step [stepControl]="firstFormGroup">
    <h1 style="color: white;">Importazione da Google</h1>
    <hr>
    <button style="margin-top:2% ;" *ngIf="!loggedIn" mat-flat-button color="primary" (click)="signInWithGoogle()">Esegui login con
      Google</button>

      <div style="margin: 2%;" *ngIf="loggedIn" >
        <img src="{{ user.photoUrl }}" referrerpolicy="no-referrer" style="border-radius: 50%;">
        <div style="color: #fff">
          <h4>{{ user.name }}</h4>
          <p>{{ user.email }}</p>
        </div>
      </div>

      <button *ngIf="loggedIn" style="background-color: red; text-transform: uppercase; color: white; float: left;" mat-button
      (click)="signOut()">Logout</button>
    <button *ngIf="loggedIn" style="background-color: #24a2c2; text-transform: uppercase; color: white; float: right;" mat-button
      matStepperNext>Avanti</button>
      

  </mat-step>
  <mat-step [stepControl]="secondFormGroup"  class="row">
    <h1 style="color: #fff;">Configura voucher</h1>
    <hr>
    <form *ngIf="registrazioneForm" [formGroup]="registrazioneForm" style="width: 35% ; margin: 1%; margin-top:2% ;"
    (ngSubmit)="registrazioneForm.valid && salva(registrazioneForm)" id="registrazioneForm">

    <mat-slide-toggle class="my-3" id="auto_login" formControlName="auto_login">Autologin</mat-slide-toggle><br><br>
    <p>
      <mat-form-field appearance="fill">
        <mat-label style="color: #fff  !important;">Entità</mat-label>
        <mat-select formControlName="idEntita" #sEntita (selectionChange)="popolaDatiEntita($event.value)">
          <mat-select-filter [placeholder]="'Cerca'" *ngIf="sEntita.focused" [displayMember]="'nome'" [array]="entita"
            (filteredReturn)="fEntita =$event"></mat-select-filter>
          <mat-option value=0>Selezionare un'entità</mat-option>
          <mat-option  [value]="e.id" *ngFor="let e of fEntita">
            {{e.nome}}</mat-option>
        </mat-select>
      </mat-form-field>
    </p>

    <h3 style="color: #fff ;" class="pt-3"><br><br>Banda massima in download per utente</h3>
    <mat-form-field  appearance="fill">
      <mat-label style="color: #fff !important;">Kbps</mat-label>
      <input style="color: #fff  !important;" matInput id="bandwidth_down" formControlName="bandwidth_down">
    </mat-form-field>
    <h3 style="color: #fff ;">Banda massima in upload per utente</h3>
    <mat-form-field   appearance="fill">
      <mat-label style="color: #fff  !important;">Kbps (0 = illimitato)</mat-label>
      <input style="color: #fff  !important;" matInput id="bandwidth_up" formControlName="bandwidth_up">
    </mat-form-field>
    <h3 style="color: #fff ;">Durata massima giornaliera</h3>
    <mat-form-field appearance="fill">
      <mat-label style="color: #fff  !important;">minuti (0 = illimitato)</mat-label>
      <input style="color: #fff  !important;" matInput id="max_time" formControlName="max_time">
    </mat-form-field>
    <h3 style="color: #fff ;">Traffico massimo giornaliero</h3>
    <mat-form-field appearance="fill">
      <mat-label style="color: #fff  !important;">MB (0 = illimitato)</mat-label>
      <input style="color: #fff  !important;" matInput id="max_traffic" formControlName="max_traffic">
    </mat-form-field>
    <h3 style="color: #fff ;">Seleziona una data di scadenza</h3>
    <mat-form-field appearance="fill" class="example-form-field">
      <mat-label>Choose a date</mat-label>
      <input style="color: #fff  !important;" matInput [matDatepicker]="datepicker" formControlName="dataAl">
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
    
  </form>

  <button (click)="sendTokenAndGetUsersGoogle()" style="background-color: #24a2c2; text-transform: uppercase; color: white; float: right;" mat-button
  matStepperNext>Avanti</button>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup">
  
    <div class="px-5 mt-5 base-filtri">
      <h1 style="color: #fff; ">Seleziona utenti</h1>
      <hr>
      <form [formGroup]="filtriForm" (ngSubmit)="filtriForm.valid && filtra(filtriForm)" style="margin: 1%; margin-top:2% ;" class="row">
        <mat-form-field appearance="fill" style="width: 10%; margin-right: 1%;background-color: #27475600;">
          <mat-label>Cerca utenti</mat-label>
          <input matInput type="text" id="testo" formControlName="testo">
        </mat-form-field>
        <mat-form-field style="width: 20%;" appearance="fill">
          <mat-label style="color: #fff  !important;">Cartella</mat-label>
          <mat-select formControlName="cartella" #sCartelle>
            <mat-select-filter [placeholder]="'Cerca'" *ngIf="sCartelle.focused" [displayMember]="'testo'" [array]="cartelleFiltro"
              (filteredReturn)="fCartelleFiltro =$event"></mat-select-filter>
            <mat-option value=''></mat-option>
            <mat-option [value]="e.testo" *ngFor="let e of fCartelleFiltro">
              {{e.testo}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-flat-button style="width: auto; margin: 2%;" color="primary" type="submit">Filtra</button>
        <button mat-flat-button style="width: auto;margin: 2%;" color="primary" type="button"
          (click)="pulisciFiltri()">Rimuovi filtri</button>
        <!-- <br><br>
        <li *ngFor="let bot of bottoniFiltro">
          <button mat-flat-button style="width: auto;" color="primary" type="button" (click)="filtraBottone(bot.testo)"> {{
            bot.testo }} </button>
        </li> -->
      </form>
    
    </div>
    <button mat-flat-button color="primary" style="margin-top: 1%; width: 15%; max-height: 50px !important; float:right ;"
    (click)="createUserSV(registrazioneForm)" [disabled]="!registrazioneForm.valid">Crea voucher</button>
    <div class="mat-elevation-z20" style="margin: 3% ;" *ngIf="googleAccount">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <ng-container matColumnDef="select" >
          <mat-header-cell *matHeaderCellDef style="width: 1%;">
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="orgUnitPath">
          <mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 30%;"> CARTELLA </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.orgUnitPath}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="familyName" >
          <mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;"> COGNOME </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.familyName}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="givenName" >
          <mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;"> NOME </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.givenName}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="primaryEmail">
          <mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 30%;"> EMAIL </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.primaryEmail}}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
        <mat-header-row *matNoDataRow>
          <mat-cell colspan="4">Nessun dato da visualizzare con il filtro applicato</mat-cell>
        </mat-header-row>
      </table>
      <mat-paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </mat-step>



</mat-horizontal-stepper>



<!-- <button *ngIf="!loggedIn" mat-flat-button color="primary" (click)="signInWithGoogle()">Esegui login con Google</button> -->





<!-- <div style="margin: 2%;">
  <img src="{{ user.photoUrl }}" referrerpolicy="no-referrer" style="border-radius: 50%;">
  <div style="color: #fff">
    <h4>{{ user.name }}</h4>
    <p>{{ user.email }}</p>
  </div>
</div>
<button mat-flat-button color="primary" style="margin: 5px;" (click)="signOut()">Esci</button>
<button mat-flat-button color="primary" style="margin: 5px;" (click)="sendTokenAndGetUsersGoogle()">Recupera
  utenti</button> -->




<!-- <form *ngIf="registrazioneForm" [formGroup]="registrazioneForm"
  (ngSubmit)="registrazioneForm.valid && salva(registrazioneForm)" id="registrazioneForm">
  <mat-slide-toggle class="my-3" id="auto_login" formControlName="auto_login">Autologin</mat-slide-toggle><br><br>
  <p>
    <mat-form-field appearance="fill">
      <mat-label style="color: black !important;">Entità</mat-label>
      <mat-select formControlName="idEntita" #sEntita (selectionChange)="popolaDatiEntita($event.value)">
        <mat-select-filter [placeholder]="'Cerca'" *ngIf="sEntita.focused" [displayMember]="'nome'" [array]="entita"
          (filteredReturn)="fEntita =$event"></mat-select-filter>
        <mat-option value=0>Selezionare un'entità</mat-option>
        <mat-option [value]="e.id" *ngFor="let e of fEntita">
          {{e.nome}}</mat-option>
      </mat-select>
    </mat-form-field>
  </p>

  <h3 style="color: #0cb5f7;" class="pt-3"><br><br>Banda massima in download per utente</h3>
  <mat-form-field appearance="fill">
    <mat-label style="color: black !important;">Kbps</mat-label>
    <input style="color: black !important;" matInput id="bandwidth_down" formControlName="bandwidth_down">
  </mat-form-field>
  <h3 style="color: #0cb5f7;">Banda massima in upload per utente</h3>
  <mat-form-field appearance="fill">
    <mat-label style="color: black !important;">Kbps (0 = illimitato)</mat-label>
    <input style="color: black !important;" matInput id="bandwidth_up" formControlName="bandwidth_up">
  </mat-form-field>
  <h3 style="color: #0cb5f7;">Durata massima giornaliera</h3>
  <mat-form-field appearance="fill">
    <mat-label style="color: black !important;">minuti (0 = illimitato)</mat-label>
    <input style="color: black !important;" matInput id="max_time" formControlName="max_time">
  </mat-form-field>
  <h3 style="color: #0cb5f7;">Traffico massimo giornaliero</h3>
  <mat-form-field appearance="fill">
    <mat-label style="color: black !important;">MB (0 = illimitato)</mat-label>
    <input style="color: black !important;" matInput id="max_traffic" formControlName="max_traffic">
  </mat-form-field>
  <hr>
  <h3 style="color: #0cb5f7;">Seleziona una data di scadenza</h3>
  <mat-form-field appearance="fill" class="example-form-field">
    <mat-label>Choose a date</mat-label>
    <input style="color: black !important;" matInput [matDatepicker]="datepicker" formControlName="dataAl">
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker>
      <mat-datepicker-actions>
        <button mat-button matDatepickerCancel>Cancel</button>
        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
      </mat-datepicker-actions>
    </mat-datepicker>
  </mat-form-field>
</form> -->

<!-- <div style="display: flex ; flex-flow: row wrap; color: #fff; padding: 2%; margin: 1%;">
      <div class="col-lg-6 mat-elevation-z20" style="margin: 1% ;width: 40%;">
        <h2 style="color: white; padding: 3%; text-transform: uppercase;">Lista utenti</h2>
        <div *ngFor="let acc of googleAccount">
          <div style="display: flex ; flex-flow: row wrap; margin-bottom: 3%;">
            <p style="margin: 3%;">{{acc.orgUnitPath}}</p>
            <p style="margin: 3%;" type="button">{{ acc.familyName }} {{ acc.givenName }}</p>
            <button mat-flat-button style="margin: 1%;" color="primary" type="button" (click)="spostaAccount(acc,true)">
              Importa </button>
          </div>          
        </div>
      </div>
      <div class="col-lg-6 mat-elevation-z20" style="margin: 1% ; width: 40%;">
        <h2 style="color: white; padding: 3%; text-transform: uppercase;">Lista utenti da importare</h2>
        <div *ngFor="let acc of googleCreateUser">
          <div style="display: flex ;flex-flow: row wrap; margin-bottom: 3%;">
            <p style="margin: 3%;">{{acc.orgUnitPath}}</p>
            <p style="margin: 3%;" type="button">{{ acc.familyName }} {{ acc.givenName }}</p>
            <button mat-flat-button style="margin: 1%; " color="warn" type="button" (click)="spostaAccount(acc,false)">
              Elimina </button>
          </div>          
        </div>
      </div>
      <button *ngIf="googleCreateUser.length>0" mat-flat-button color="primary"
        style="margin-top: 1%; width: 15%; max-height: 50px !important;" (click)="createUserSV(registrazioneForm)"
        [disabled]="!registrazioneForm.valid">Crea utenti</button>

    </div> -->
<!-- <button mat-flat-button color="primary" style="margin-top: 1%; width: 15%; max-height: 50px !important;"
  (click)="createUserSV(registrazioneForm)" [disabled]="!registrazioneForm.valid">Crea utenti</button>

<div class="px-5 mt-5 base-filtri">
  <h1>Filtra utenti</h1>
  <form [formGroup]="filtriForm" (ngSubmit)="filtriForm.valid && filtra(filtriForm)" class="row">
    <mat-form-field appearance="fill" style="width: 10%; margin-right: 1%;background-color: #27475600;">
      <mat-label>Ricerca</mat-label>
      <input matInput type="text" id="testo" formControlName="testo">
    </mat-form-field>
    <button mat-flat-button style="width: auto;" color="primary" type="submit">Filtra</button>
    <button mat-flat-button style="width: auto;" color="primary" type="button"
      (click)="pulisciFiltri()">Pulisci</button>
    <br><br>
    <li *ngFor="let bot of bottoniFiltro">
      <button mat-flat-button style="width: auto;" color="primary" type="button" (click)="filtraBottone(bot.testo)"> {{
        bot.testo }} </button>
    </li>
  </form>

</div>
<div class="mat-elevation-z20" *ngIf="googleAccount">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.id}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="orgUnitPath">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ORGANIZZAZIONE </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.orgUnitPath}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="familyName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> COGNOME </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.familyName}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="givenName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> NOME </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.givenName}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="primaryEmail">
      <mat-header-cell *matHeaderCellDef mat-sort-header> EMAIL </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.primaryEmail}}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
    </tr>
  </table>
  <mat-paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div> -->