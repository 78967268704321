import { AddNethesisComponent } from './../../../_dialog/add-nethesis/add-nethesis.component';
import { Entita } from './../../../_models/entita';
import { EntitaService } from './../../../_services/entita.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DialogClass } from 'src/app/_models/dialogClass';
import { SiNoDialogComponent } from 'src/app/_dialog/si-no-dialog/si-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiMsg } from 'src/app/_models/apiMessage';
import { SnackBarClass } from 'src/app/_models/snackBarClass';
import { SnackbarComponentComponent } from 'src/app/_component/snackbar-component/snackbar-component.component';
import { EntitaDialogComponent } from 'src/app/_dialog/entita-dialog/entita-dialog.component';

@Component({
  selector: 'app-entita',
  templateUrl: './entita.component.html',
  styleUrls: ['./entita.component.scss']
})
export class EntitaComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  entita:Entita[];

  displayedColumns: string[] = ['id', 'nome','aggancia', 'modifica', 'elimina'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  apiMsg: ApiMsg;

  constructor(private entitaService:EntitaService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getEntita();    
  }

  getEntita(){
    this.entitaService.GetEntitaList().subscribe(
      response=>{
        this.entita=response;      
        this.dataSource = null;
        this.dataSource = new MatTableDataSource(this.entita);
        this.entita = this.entita.sort((a, b) => (a.nome > b.nome ? -1 : 1));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;          
        
      }
    )
  }

  applicaFiltro(filtro: string) {
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  elimina(entita:Entita){
    let dialogData: DialogClass = new DialogClass("Elimina entità " + entita.nome, "Sicuri di voler eliminare l'entità?", true, true, true)
    let dialogRef = this.dialog.open(SiNoDialogComponent, { data: dialogData })
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          this.entitaService.DelEntita(entita.id).subscribe(
            response => {
              this.apiMsg = response;
              let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false);
              let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
              this.getEntita();
            }
          )
        }
      }
    )
  }
  

  crea(){
    let dialogRef = this.dialog.open(EntitaDialogComponent);
    dialogRef.afterClosed().subscribe(
      response => {
        if (response.event == "OK") {
          this.getEntita();
          let snackBarData: SnackBarClass = new SnackBarClass("Entità creata correttamente", false);
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })

        }
      }
    )
  }

  openFileDialog(entita: Entita) {
    let dialogref = this.dialog.open(EntitaDialogComponent,{ data : { entita: entita }});
    dialogref.afterClosed().subscribe(
      response=>{
        if (response.event=="OK"){
          this.getEntita();
          let snackBarData: SnackBarClass = new SnackBarClass("Entità modificata correttamente", false);
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
        }
      }
    )
  }

  agganciaHotspot(entita:Entita){
    let dialogref = this.dialog.open(AddNethesisComponent,{ data : { entita: entita }});
    dialogref.afterClosed().subscribe(
      response=>{
        if (response.event=="OK"){
          this.getEntita();
          let snackBarData: SnackBarClass = new SnackBarClass("Entità agganciata correttamente", false);
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
        }
      }
    )
  }


}
