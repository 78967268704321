<div class="row">
    <h1 style="color: white;">Gestione utenti</h1>    
    <div class="col-lg-12 ">
        <form [formGroup]="filtriForm" (ngSubmit)="filtriForm.valid && getUtenti(filtriForm)" style="margin: 1%; margin-top:2% ;">
            <mat-form-field appearance="fill" style="width: 10%; margin-right: 1%;">
              <mat-label>Cerca utenti</mat-label>
              <input matInput type="text" id="testo" formControlName="testo">
            </mat-form-field>
            <mat-form-field appearance="fill"  style="width: 10%; margin-right: 1%;">
              <mat-label style="color: #fff  !important;">Cartella</mat-label>
              <mat-select formControlName="cartella" #sCartelle>
                <mat-select-filter [placeholder]="'Cerca'" *ngIf="sCartelle.focused" [displayMember]="'testo'" [array]="cartelleFiltro"
                  (filteredReturn)="fCartelleFiltro =$event"></mat-select-filter>
                <mat-option value=''></mat-option>
                <mat-option [value]="e.testo" *ngFor="let e of fCartelleFiltro">
                  {{e.testo}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill"  style="width: 10%; margin-right: 1%;">
                <mat-label>Stato voucher</mat-label>
                <mat-select formControlName="statoVoucher">
                    <mat-option value=0>Tutti</mat-option>
                    <mat-option value=1>Non scaduti</mat-option>
                    <mat-option value=2>In scadenza 2 settimane</mat-option>
                    <mat-option value=-1>Scaduti</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-flat-button style="width: auto; margin: 2%;" color="primary" type="submit">Filtra</button>                        
          </form>
                    
    </div>
    
    <button mat-flat-button class="ml-4" style="margin: 5px; background-color: rgb(0, 216, 0);" color="primary" (click)="creaUtente()">Crea utente</button>
    <button mat-flat-button class="ml-4" style="margin: 5px;" color="primary" (click)="cambiaScadenzaUtenti()">Modifica scadenza</button>
    <button mat-flat-button class="ml-4" style="margin: 5px;background-color: rgb(255, 208, 0);" color="danger" (click)="bloccaUtenti()">Blocca selezionati</button>
    <button mat-flat-button class="ml-4" style="margin: 5px;" color="warn" (click)="eliminaUtenti()">Elimina selezionati</button>
    <button mat-flat-button style="width: auto; margin: 5px; float: right;" color="primary" type="button" (click)="excel(filtriForm)">Esporta Excel</button> 
    
    
    
    <div class="col-lg-12">        
        <div class="mat-elevation-z20">

            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef style="border-bottom-style: unset;">
                      <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" style="border-bottom-style: unset;">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                <!-- <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.id}}
                    </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="nominativo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> NOMINATIVO </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.cognome}}  {{row.nome}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="googleOrgUnitPath">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ORGANIZZAZIONE </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.googleOrgUnitPath}}
                    </mat-cell>
                </ng-container>                
                <ng-container matColumnDef="dataScadenzaVoucher">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> SCADENZA VOUCHER </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.scadenza_voucher | date:'dd/MM/yyyy' }}
                    </mat-cell>
                </ng-container>                                
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> MAIL </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.user_mail}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="modifica">
                    <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                    <mat-cell *matCellDef="let row"><i class="material-icons"
                            (click)="modifica(row)">build</i></mat-cell>
                </ng-container>
                <ng-container matColumnDef="elimina">
                    <mat-header-cell *matHeaderCellDef>ELIMINA</mat-header-cell>
                    <mat-cell *matCellDef="let row"><i class="material-icons"  (click)="eliminaUtente(row)">delete</i></mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                </tr>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>