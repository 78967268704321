<h2 mat-dialog-title> Aggancio </h2>
<mat-dialog-content style="width: 40vh!important;">    
    <form *ngIf="formHotspots && visHotspot" [formGroup]="formHotspots" (ngSubmit)="formHotspots.valid && salvaEntita(formHotspots)" id=formHotspots>
        <h3>Hotspot</h3>
        <p>
            <mat-form-field appearance="fill">
                <mat-label >Seleziona Hotspot</mat-label>
                <mat-select formControlName="idHotspotNethspot" #sHotspots>
                    <mat-select-filter [placeholder]="'Cerca'" *ngIf="sHotspots.focused"
                                    [displayMember]="'description'" [array]="hotspots"
                                    (filteredReturn)="fHotspots =$event"></mat-select-filter>
                    <mat-option value=0>Tutti</mat-option>
                    <mat-option [value]="hotspot.id" *ngFor="let hotspot of fHotspots">
                        {{hotspot.description}}</mat-option>
                </mat-select>
            </mat-form-field>        
        </p>
        <p style="margin-top: 20px;">
            <button mat-flat-button color="primary" id=btnSalvaEntita [disabled]="!formHotspots.valid" type="submit">
                <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
            </button>
        </p>
    </form>    
    <form *ngIf="formUnits && visUnits" [formGroup]="formUnits" (ngSubmit)="formUnits.valid && salvaSede(formUnits)" id=formUnits>
        <h3>Seleziona Units</h3>
        <p>
            <mat-form-field appearance="fill" >
                <mat-label >Units</mat-label>
                <mat-select formControlName="idUnitNethspot" #sUnits>
                    <mat-select-filter [placeholder]="'Cerca'" *ngIf="sUnits.focused"
                                    [displayMember]="'description'" [array]="units"
                                    (filteredReturn)="fUnits =$event"></mat-select-filter>
                    <mat-option value=0>Tutti</mat-option>
                    <mat-option [value]="unit.id" *ngFor="let unit of fUnits">
                        {{unit.description}}</mat-option>
                </mat-select>
            </mat-form-field>        
        </p>
        <p style="margin-top: 20px;">
            <button mat-flat-button color="primary" id=btnSalvaSede [disabled]="!formUnits.valid" type="submit">
                <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
            </button>
        </p>
    </form>
</mat-dialog-content>