import { Utenti } from './_models/utenti';
import { UtentiService } from './_services/utenti.service';
import { Component, OnInit } from '@angular/core';
import { User } from './_models/user';
import { AccountService } from './_services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  sideBarOpen=true;
  title = 'Super Vise 2';
  users:any;
  

  constructor(public accountService:AccountService, public utentiService:UtentiService){}
  ngOnInit(): void {
    this.setCurrentUser();
    this.setCurrentUtente();
  }

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;    
  }

  setCurrentUser(){
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (user){      
      this.accountService.setCurrentUser(user);
    }     
  }

  setCurrentUtente(){
    const utente:Utenti = JSON.parse(localStorage.getItem('utente'));
    if (utente){
      this.utentiService.setCurrentUtente(utente);
    }
  }

}
