import { User } from 'src/app/_models/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PatchMethod } from './../../../_models/patchMethod';
import { AccountService } from 'src/app/_services/account.service';
import { Component, OnInit } from '@angular/core';
import { FunctionLocalStorage } from 'src/app/_function/localstorage-function';
import { ToastrService } from 'ngx-toastr';
import { SnackBarClass } from 'src/app/_models/snackBarClass';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponentComponent } from 'src/app/_component/snackbar-component/snackbar-component.component';
import { ApiMsg } from 'src/app/_models/apiMessage';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { Photo } from 'src/app/_models/photo';

@Component({
  selector: 'app-configurator-user',
  templateUrl: './configurator-user.component.html',
  styleUrls: ['./configurator-user.component.scss']
})
export class ConfiguratorUserComponent implements OnInit {
  f:FormGroup;
  hidePassword1 = true;
  user:User;
  apiMsg: ApiMsg;

  uploader: FileUploader;
  hasBaseDropzoneOver = false;
  baseUrl = environment.apiUrl;

  constructor(private accountService:AccountService,private functionLS: FunctionLocalStorage,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.user = this.functionLS.getCurrentUser();
    this.f = new FormGroup({
      'userNameNethspot':new FormControl('',[Validators.required]),
      'passwordNethspot': new FormControl('',[Validators.required])
    })
  }

  get userNameNethspot(){
    return this.f.get('userNameNethspot');
  }

  get passwordNethspot(){
    return this.f.get('passwordNethspot');
  }

  salva(dati:FormGroup){
    
    let paramUsernameNethspot=new PatchMethod(dati.value.userNameNethspot,"/UserNameNethspot","replace");
    let paramPasswordNethspot=new PatchMethod(dati.value.passwordNethspot,"/PasswordNethspot","replace");

    this.accountService.editUsernethspot(this.user.userName,[paramUsernameNethspot,paramPasswordNethspot]).subscribe(
      response=>{
        this.apiMsg=response;
        
        let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false);
        let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
        
      }
    )
  }

  fileOverBase(e: any) {
    this.hasBaseDropzoneOver = e;
  }

  inizializeUploader() {
    this.uploader = new FileUploader({
      url: this.baseUrl + "account/add-photo",
      authToken: `Bearer ` + this.user.token,
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024
    })

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    }

    // this.uploader.onSuccessItem = (item, response, status, headers) => {
    //   if (response) {
    //     const photo:Photo = JSON.parse(response);
    //     this.member.photos.push(photo);
    //     if (photo.isMain){
    //       this.user.photoUrl=photo.url;
    //       this.member.photoUrl=photo.url;
    //       this.accountService.setCurrentUser(this.user);
    //     }
    //   }
    // }
  }

}
