import { Sedi } from './../../_models/sedi';
import { Component, Inject, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/_services/admin.service';
import { SnackBarClass } from 'src/app/_models/snackBarClass';
import { SnackbarComponentComponent } from 'src/app/_component/snackbar-component/snackbar-component.component';

@Component({
  selector: 'app-user-sedi-dialog',
  templateUrl: './user-sedi-dialog.component.html',
  styleUrls: ['./user-sedi-dialog.component.scss']
})
export class UserSediDialogComponent implements OnInit {
  user: User;
  sedi: Sedi[];
  arraySedi: number[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) private data, private dialogRef: MatDialogRef<UserSediDialogComponent>, private adminService: AdminService,
    private snackBar: MatSnackBar) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    let tmpArray: number[] = [];
    this.data.user.entita.forEach(function (x) {
      tmpArray.push(x.id);
    });
    this.arraySedi = tmpArray;
    this.user = this.data.user;
    this.sedi = this.data.sedi; 
       
  }

  selectSede(idSede: number, event: any) {
    if (event == true) {
      this.arraySedi.push(idSede)
    } else {
      this.arraySedi.splice(this.arraySedi.indexOf(idSede), 1);
    }
  }

  toggleCheckBox(elementId) {
    return (this.arraySedi.indexOf(elementId) != -1) ? true : false;
  }

  salva() {
    if (this.arraySedi.length == 0) {
      let snackBarData: SnackBarClass = new SnackBarClass("Selezionare almeno un'entità", false);
      let snackBarRef = this.snackBar.openFromComponent(SnackbarComponentComponent, { data: snackBarData, duration: 2000 })
    }else{
      this.adminService.editUserSedi(this.user.userName, this.arraySedi).subscribe(
        response => {
          this.dialogRef.close({ event: "OK" });
        }
      )
    }

    
  }
}
