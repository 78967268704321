import { ImportGoogleComponent } from './pages/importazioni/import-google/import-google.component';
import { ImportUtentiComponent } from './pages/importazioni/import-utenti/import-utenti.component';
import { SediComponent } from './pages/_Shared/sedi/sedi.component';
import { ConfiguratorUserComponent } from './pages/_Shared/configurator-user/configurator-user.component';
import { EntitaComponent } from './pages/_Shared/entita/entita.component';
import { UtenteManagementComponent } from './pages/_Shared/utente-management/utente-management.component';
import { UtentiGuard } from './_guards/utenti.guard';
import { SuperAdminGuard } from './_guards/superAdmin.guard';
import { ModeratorGuard } from './_guards/moderator.guard';
import { AuthGuard } from './_guards/auth.guard';
import { AdminGuard } from './_guards/admin.guard';
import { LoginComponent } from './pages/_Shared/login/login.component';
import { TestErrorComponent } from './errors/test-error/test-error.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ServerErrorComponent } from './errors/server-error/server-error.component';
import { UserManagementComponent } from './pages/_Shared/user-management/user-management.component';

const routes: Routes = [

  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: 'configurator-user', component: ConfiguratorUserComponent, canActivate: [AuthGuard] },
  { path: 'management-user', component: UserManagementComponent, canActivate: [AuthGuard] },
  { path: 'management-utente', component: UtenteManagementComponent, canActivate: [AuthGuard] },

  { path: "utente", loadChildren: () => import('./pages/utente/utente.module').then(m => m.UtenteModule), canActivate: [UtentiGuard] },
  {path:'importazioni/import-utenti',component: ImportGoogleComponent,canActivate:[AuthGuard]},

  //Questa parte di codice la uso quando devo proteggere più rotte con la stessa guardia
  {
    path: "",
    runGuardsAndResolvers: "always",
    canActivate: [AuthGuard],
    children: [
      { path: "superadmin", loadChildren: () => import('./pages/superadmin/superadmin.module').then(m => m.SuperAdminModule), canActivate: [SuperAdminGuard] },
      { path: "admin", loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard] },
      { path: "moderator", loadChildren: () => import('./pages/moderator/moderator.module').then(m => m.ModeratorModule), canActivate: [ModeratorGuard] },
      { path: "user", loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
      { path: "firewall", loadChildren: ()=> import('./pages/SVFirewall/svFirewall.module').then(m=>m.svFirewallModule)},
      { path: "entita", component: EntitaComponent },
      { path:"sedi",component:SediComponent}
    ]
  },

  { path: 'errors', component: TestErrorComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'server-error', component: ServerErrorComponent },
  { path: '**', component: NotFoundComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
