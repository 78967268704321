import { AccountService } from './../_services/account.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private accountService: AccountService, private router: Router) { }

  canActivate() {
    if(this.accountService.isAuth()){
      return true;
    }else{
      this.router.navigate(['/login'])
    }   
  }

}
