import { ApiMsg } from 'src/app/_models/apiMessage';
import { Entita } from './../_models/entita';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { PatchMethod } from '../_models/patchMethod';


@Injectable({
    providedIn: 'root'
  })
  export class EntitaService {
    baseUrl = environment.apiUrl;
  
    constructor(private http: HttpClient) { }
  
    GetEntitaList() {
      return this.http.get<Entita[]>(this.baseUrl + "entita");            
    }  

    GetEntita(id:number){      
      return this.http.get<Entita>(this.baseUrl + "entita/" + id);
    }

    UpdEntita(id:number,campi:PatchMethod[]){
      return this.http.patch<ApiMsg>(this.baseUrl + "entita/" + id,campi);
  }

    InsEntita(entita:Entita){
        return this.http.post<Entita>(this.baseUrl + "entita/inserisci",entita);
    }

    DelEntita(id:number){
        return this.http.delete<ApiMsg>(this.baseUrl + "entita/elimina/" + id)
    }        
    
  }