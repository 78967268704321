<mat-nav-list style="background-color: #242424;color: white;">
    <div class="profile-card">
        <div class="header ">
            <h4 style="color: #0cb5f7; margin-top: 4vh;"> {{ user.nome }} {{ user.cognome }} </h4>
            <h5> {{ user.cliente?.ragioneSociale }} </h5>
            <h5 style="margin-bottom: 4vh;"> {{ user.userName }} </h5>
        </div>
        <img src={{user.photoUrl}} alt="{{user.nome}} {{user.cognome}}">
    </div>
    <mat-divider></mat-divider>

    <a style="color: white;" mat-list-item [routerLink]="['/']" routerLinkActive="list-item-active">
        <mat-icon class="icon">dashboard</mat-icon>Dashboard
    </a>


    <mat-divider></mat-divider>

    <mat-expansion-panel style="background-color: #242424; " (opened)="panelAmministrazione = true" (closed)="panelAmministrazione = false"
        *ngIf="accountService.isSuperAdmin()">
        <mat-expansion-panel-header>
            <mat-panel-title style="color: white;" >
                <mat-icon class="icon">admin_panel_settings</mat-icon>BluHero
            </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-list-item style="color: white;" class="active"  [routerLink]="['/superadmin/clienti']" routerLinkActive="list-item-active">Clienti</a>
        <a mat-list-item style="color: white;" class="active"   [routerLink]="['/entita']" routerLinkActive="list-item-active">Entità</a>
        <a mat-list-item style="color: white;" class="active"   [routerLink]="['/management-user']" routerLinkActive="list-item-active">Gestione Amministratori</a>
        <a mat-list-item style="color: white;" class="active"   [routerLink]="['/management-utente']" routerLinkActive="list-item-active">Utenti</a>        
    </mat-expansion-panel>

    <mat-divider></mat-divider>

    <mat-expansion-panel style="background-color: #242424; " (opened)="panelAmministrazione = true" (closed)="panelAmministrazione = false"
        *ngIf="accountService.isModerator()">
        <mat-expansion-panel-header>
            <mat-panel-title style="color: white;" >
                <mat-icon class="icon">inbox</mat-icon>Amministrazione
            </mat-panel-title>
        </mat-expansion-panel-header>        
        <a mat-list-item style="color: white;" class="active"   [routerLink]="['/entita']" routerLinkActive="list-item-active" *ngIf="accountService.isAdmin()">Entità</a>
        <a mat-list-item style="color: white;" class="active"   [routerLink]="['/sedi']" routerLinkActive="list-item-active" *ngIf="accountService.isAdmin()">Sedi</a>
        <a mat-list-item style="color: white;" class="active"   [routerLink]="['/management-user']" routerLinkActive="list-item-active">Gestione Amministratori</a>
        <a mat-list-item style="color: white;" class="active"   [routerLink]="['/management-utente']" routerLinkActive="list-item-active">Utenti</a>
        <a mat-list-item style="color: white;" class="active"   [routerLink]="['/importazioni/import-utenti']" routerLinkActive="list-item-active">Importazioni utenti</a>
    </mat-expansion-panel>    

    <mat-divider></mat-divider>

    <mat-expansion-panel style="background-color: #242424;" (opened)="panelModuloFirewall = true" (closed)="panelModuloFirewall = false"
        *ngIf="user.moduli.includes('Firewall')">
        <mat-expansion-panel-header>
            <mat-panel-title style="color: white;" >
                <mat-icon class="icon">devices icon</mat-icon>Rete
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel  style="background-color: #242424;" (opened)="panelSubModuloNethspot = true" (closed)="panelSubModuloNethspot = false"
            *ngIf="user.moduli.includes('Firewall')">
            <mat-expansion-panel-header>
                <mat-panel-title style="color: white;" >
                    <mat-icon class="icon">rss_feed</mat-icon>Connessioni
                </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/neth-hotspots']" routerLinkActive="list-item-activate"  *ngIf="accountService.isAdmin()">Hotspots</a>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/auth-mac-address']" routerLinkActive="list-item-active">Auth Mac Address</a>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/sessions']" routerLinkActive="list-item-active">Sessioni attive</a>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/histories']" routerLinkActive="list-item-active" *ngIf="accountService.isAdmin()">Archivio sessioni</a>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/neth-users']" routerLinkActive="list-item-active" *ngIf="accountService.isAdmin()">Gestione profili di rete</a>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/statsusers']" routerLinkActive="list-item-active" *ngIf="accountService.isAdmin()">Statistiche profili di rete</a>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/statsunits']" routerLinkActive="list-item-active" *ngIf="accountService.isAdmin()">Statistiche sedi</a>
            
        </mat-expansion-panel>


        <!-- <mat-expansion-panel style="background-color: #242424;" (opened)="panelSubModuloServer = true" (closed)="panelSubModuloServer = false" *ngIf="user.moduli.includes('Firewall')"></mat-expansion-panel> -->
        <mat-expansion-panel style="background-color: #242424;" (opened)="panelSubModuloServer = true" (closed)="panelSubModuloServer = false" *ngIf="accountService.isAdmin()">
            <mat-expansion-panel-header>
                <mat-panel-title style="color: white;" >
                    <mat-icon class="icon">security</mat-icon>Firewall
                </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/firewalls']" routerLinkActive="list-item-active">Elenco</a>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/firewallscommands/0']" routerLinkActive="list-item-active">Comandi</a>
            <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/abuse-ip']" routerLinkActive="list-item-active">Abuse Ip</a>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel style="background-color: #242424;" (opened)="panelSubModuloServer = true" (closed)="panelSubModuloServer = false" *ngIf="user.moduli.includes('Firewall')"></mat-expansion-panel> -->
        <mat-expansion-panel style="background-color: #242424;" (opened)="panelSubModuloServer = true" (closed)="panelSubModuloServer = false" *ngIf="accountService.isAdmin()">
        <mat-expansion-panel-header>
            <mat-panel-title style="color: white;" >
                <mat-icon class="icon">phonelink_lock</mat-icon>Content Filtering
            </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-list-item style="color: white;" class="active"  [routerLink]="['/firewall/firewalls']" routerLinkActive="list-item-active"><img class="img-fluid " src="/assets/img/flashstart-logo.png"></a>
      
    </mat-expansion-panel>

    </mat-expansion-panel>
    <mat-expansion-panel style="background-color: #242424;" (opened)="panelModuloMensa = true" (closed)="panelModuloMensa = false"
        *ngIf="user.moduli.includes('Mensa')">
        <mat-expansion-panel-header>
            <mat-panel-title style="color: white;" >
                <mat-icon class="icon">food_bank</mat-icon>Mensa
            </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-list-item [routerLink]="['/management-user']" style="color: white;" class="active" routerLinkActive="list-item-active">Lista</a>
    </mat-expansion-panel>
    <mat-expansion-panel style="background-color: #242424;" (opened)="panelModuloOrdini = true" (closed)="panelGestioneAccount = false"
        *ngIf="user.moduli.includes('Ordini')">
        <mat-expansion-panel-header>
            <mat-panel-title style="color: white;" >
                <mat-icon class="icon">document_scanner</mat-icon>Ordini
            </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-list-item [routerLink]="['/management-user']" style="color: white;" class="active"  routerLinkActive="list-item-active">Lista</a>
    </mat-expansion-panel>

    <!-- <a mat-list-item [routerLink]="['/contacts']" routerLinkActive="list-item-active">
        <mat-icon>import_contacts</mat-icon>
        Contacts
    </a>

    <a mat-list-item [routerLink]="['/contacts']" routerLinkActive="list-item-active">
        <mat-icon>contact_phone</mat-icon>
        Leads
    </a> -->

</mat-nav-list>