import { FiltroUtenti } from './../_models/_filters/filtroUtenti';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    baseUrl = environment.apiUrl + "esportazioni";

    constructor(private http: HttpClient) { }

    UtentiExcel(filtro:FiltroUtenti){
        return this.http.post(this.baseUrl + "/utenti-excel",filtro,{responseType:"blob"});
    }
}