import { Sedi } from './sedi';
import { Cliente } from './clienti';
export class Entita{
    constructor(
        public id:number=0,
        public nome:string="",
        public idCliente:number=0,
        public idHotspotNethspot:number=0,

        public ultimaDataScadenzaVoucher:Date,
        public ultimo_auto_login:boolean=false,
        public ultimo_bandwidth_down:number=0,
        public ultimo_bandwidth_up:number=0,
        public ultimo_max_traffic:number=0,
        public ultimo_max_time:number=0,

        public clienti:Cliente=null,
        public sedi:Sedi[]=[]
    ){}

    
}