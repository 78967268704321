<ngx-spinner>
    <h3>Loading...</h3>
</ngx-spinner>
<app-header (toggleSideBarForMe)="sideBarToggler()" *ngIf="(accountService.currentUser$ | async) as user" ></app-header>
<app-header-utenti (toggleSideBarForMe)="sideBarToggler()" *ngIf="(utentiService.currentUtente$ | async) as utente" ></app-header-utenti>
<mat-drawer-container>
    <mat-drawer #sidebar id="sidebar" mode="side" [opened]="true" [class.menu-open]="sideBarOpen"
        [class.menu-close]="!sideBarOpen" *ngIf="(accountService.currentUser$ | async) as user">
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
<app-footer></app-footer>