import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class BusyService {
  busyRequestCount = 0;

  constructor(private spinnerService: NgxSpinnerService) { }

  busy(){
    this.busyRequestCount++;
    this.spinnerService.show(undefined,{
      type:'ball-scale-multiple',
      bdColor:'rgba(0, 0, 0, 0.8)',
      color:'#0eb5f7',
      fullScreen:true,
      size:'large'
    })
  }

  idle(){
    this.busyRequestCount--;
    if (this.busyRequestCount<=0){
      this.busyRequestCount=0;
      this.spinnerService.hide();
    }
  }
}
