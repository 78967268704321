<mat-toolbar class="grad">
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSideBar()" *ngIf="(accountService.currentUser$ | async) as user">
            <mat-icon>menu</mat-icon>
        </button>
        <span class="py-4"><img src="/assets/img/edu-wifi-logo.png" class="img-fluid"/></span>
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
            <ul fxLayout="row" fxLayoutGap="20px">
                <li>
                    <button mat-icon-button [routerLink]="['/configurator-user']">
                        <mat-icon>settings</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button>
                        <mat-icon>help_outline</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>person_outline</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="['/login']"  *ngIf="(accountService.currentUser$ | async) === null">
                            <mat-icon>get_app</mat-icon>
                            Sign in
                        </button>
                        <button mat-menu-item (click)="logout()" *ngIf="(accountService.currentUser$ | async) as user">
                            <mat-icon>exit_to_app</mat-icon>
                            Sign out
                        </button>
                    </mat-menu>

                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>