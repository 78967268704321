import { NethHotspotsService } from 'src/app/_services/nethHotspots.service';
import { FunctionLocalStorage } from './../../_function/localstorage-function';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntitaService } from './../../_services/entita.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { User } from 'src/app/_models/user';
import { NethHotspots } from 'src/app/_models/_nethspotModels/nethHotspots';
import { Entita } from 'src/app/_models/entita';

@Component({
  selector: 'app-entita-dialog',
  templateUrl: './entita-dialog.component.html',
  styleUrls: ['./entita-dialog.component.scss']
})
export class EntitaDialogComponent implements OnInit {
  f:FormGroup;
  fEntitaPadri;
  isModifica:boolean=false;

  constructor(@Inject(MAT_DIALOG_DATA) private data,private entitaService:EntitaService,private dialogRef:MatDialogRef<EntitaDialogComponent>,private functionLS:FunctionLocalStorage, 
    private nethHotspotService:NethHotspotsService) { }

  ngOnInit(): void {
    this.f = new FormGroup({
      'name': new FormControl('',[Validators.required]),
      'creaHotspot':new FormControl(false),
      'description': new FormControl(''),
      'business_name': new FormControl(''),
      'business_vat': new FormControl(''),
      'business_address': new FormControl(''),
      'business_email': new FormControl(''),
      'business_dpo': new FormControl(''),
      'business_dpo_mail': new FormControl(''),
    })
    if(this.data){
      this.isModifica=true;
      this.f.controls['name'].setValue(this.data.entita.nome)
    }
  }

  get name(){
    return this.f.get('name');
  }    

  get creaHotspot(){
    return this.f.get('creaVoucher');
  }

  get description(){
    return this.f.get('description');
  }
  get business_name(){
    return this.f.get('business_name');
  }
  get business_vat(){
    return this.f.get('business_vat');
  }
  get business_address(){
    return this.f.get('business_address');
  }
  get business_email(){
    return this.f.get('business_email');
  }
  get business_dpo(){
    return this.f.get('business_dpo');
  }
  get business_dpo_mail(){
    return this.f.get('business_dpo_mail');
  }

  public errorHandling = (control: string, error: string) => {
    return this.f.controls[control].hasError(error);
  }


  // salva(dati:FormGroup){
  //   let entita:Entita = dati.value;    
  //   let user:User=this.functionLS.getCurrentUser();
  //   entita.idCliente=user.idCliente;
    // this.entitaService.InsEntita(entita).subscribe(
    //   response=>{

    //     this.dialgoRef.close({event:"OK"});
    //   }
    // )
  // }

  salva(dati:FormGroup){
    
    //Popolo l'enità
    let user:User=this.functionLS.getCurrentUser();
    let entita:Entita = new Entita(0,dati.value.name,user.idCliente,0,new Date(),false,0,0,0,0,null,[]);    

    //Popolo l'hotspot
    let hotspot:NethHotspots = dati.value;
    hotspot.onAction=true;
    hotspot.privacy_disclaimer_id=0;
    hotspot.tos_disclaimer_id=0;

    //Creo entità e hotspot
    this.nethHotspotService.InsertHotspot(hotspot).subscribe(
      response=>{
        
        this.entitaService.InsEntita(entita).subscribe(
          response=>{
    
            this.dialogRef.close({event:"OK"});
          }
        )
      }
    )

  }

}
