import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-data-giorno-dialog',
  templateUrl: './data-giorno-dialog.component.html',
  styleUrls: ['./data-giorno-dialog.component.scss']
})
export class DataGiornoDialogComponent implements OnInit {
  f!:FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) private data,private dialogRef: MatDialogRef<DataGiornoDialogComponent>) { }

  ngOnInit(): void {
    console.log(this.data);
    
    this.f = new FormGroup({
      'dataGiorno':new FormControl(this.data)
    })
  }

  salva(dati:FormGroup){
    console.log(dati);
    
    this.dialogRef.close({ data: dati.value.dataGiorno });
  }

}
