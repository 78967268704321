import { NethUnits } from './../_models/_nethspotModels/nethUnits';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn:"root"
})
export class NethUnitsService{
    baseUrl= environment.apiUrl;

    constructor(private http:HttpClient){}

    GetNethUnitsList(){
        return this.http.get<NethUnits[]>(this.baseUrl + "nethunits");
    }

    GetNethUnitsListByIdHotspot(idHotspot:number){
        return this.http.get<NethUnits[]>(this.baseUrl + "nethunits/lista/" + idHotspot);
    }
}