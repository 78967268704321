import { FiltroUtenti } from './../_models/_filters/filtroUtenti';
import { Utenti } from './../_models/utenti';
import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiMsg } from '../_models/apiMessage';
import { PatchMethod } from '../_models/patchMethod';

@Injectable({
    providedIn: 'root'
  })
  export class UtentiService {
    baseUrl = environment.apiUrl;
    private currentUtenteSource = new ReplaySubject<Utenti>(1);
    currentUtente$ = this.currentUtenteSource.asObservable();
    private authenticated = false;
        
    constructor(private http: HttpClient) { }
  
    login(voucher:string) {
      return this.http.post(this.baseUrl + "utenti/login/" + voucher, null).pipe(
        map((response: Utenti) => {
          const utente = response;
          if (utente) {
            this.setCurrentUtente(utente);
            this.authenticated = true;
          }
        })
      )
    }
  
    register(model: any) {
      return this.http.post(this.baseUrl + "utenti/register", model).pipe(
        map((utente: Utenti) => {
          if (utente) {
          }
        })
      )
    }

    Update(id:number,campi:PatchMethod[]){
      return this.http.patch<ApiMsg>(this.baseUrl + "utenti/" + id,campi);
  }

    registerMultiple(utenti:Utenti[]){
      return this.http.post<Utenti[]>(this.baseUrl + "utenti/register-multiple",utenti);
    }
  
    delete(id:number){
      return this.http.delete<ApiMsg>(this.baseUrl + "utenti/delete/" + id);
    }

    deleteArray(utenti:Utenti[]){
      return this.http.post<ApiMsg>(this.baseUrl + "utenti/delete-array",utenti);
    }

    bloccaArray(utenti:Utenti[]){
      return this.http.post<ApiMsg>(this.baseUrl + "utenti/blocca-array",utenti);
    }
  
    setCurrentUtente(utente: Utenti) {      
  
      localStorage.setItem('utente', JSON.stringify(utente));
      this.currentUtenteSource.next(utente);
      this.authenticated = true;      
    }
  
    logout() {
      localStorage.removeItem('utente');
      this.currentUtenteSource.next(null);
      this.authenticated = false;
    }
  
    getDecodedToken(token) {
      return JSON.parse(atob(token.split('.')[1]));
    }    
  
    //Funzione messa per determinare se un utente è loggato o meno
    isAuth() {
      return this.authenticated;
    }

    getUtenti(filtro:FiltroUtenti){
      return this.http.post<Utenti[]>(this.baseUrl + "utenti",filtro);
    }
  }