import { SediService } from './../../_services/sedi.service';
import { Sedi } from './../../_models/sedi';
import { EntitaService } from './../../_services/entita.service';
import { Entita } from './../../_models/entita';
import { FormGroup, FormControl } from '@angular/forms';
import { NethHotspots } from './../../_models/_nethspotModels/nethHotspots';
import { NethUnits } from './../../_models/_nethspotModels/nethUnits';
import { NethUnitsService } from './../../_services/nethUnitsService';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NethHotspotsService } from 'src/app/_services/nethHotspots.service';
import { PatchMethod } from 'src/app/_models/patchMethod';

@Component({
  selector: 'app-add-nethesis',
  templateUrl: './add-nethesis.component.html',
  styleUrls: ['./add-nethesis.component.scss']
})
export class AddNethesisComponent implements OnInit {

  hotspots: NethHotspots[];
  fHotspots;

  units: NethUnits[];
  fUnits;

  formHotspots: FormGroup;
  formUnits: FormGroup;

  visHotspot:boolean=false;
  visUnits:boolean=false;

  constructor(@Inject(MAT_DIALOG_DATA) private data, private dialogRef: MatDialogRef<AddNethesisComponent>, private nethHotspotService: NethHotspotsService,
    private nethUnitsService: NethUnitsService, private entitaService:EntitaService, private sediService:SediService) { }

  ngOnInit(): void {
    if (this.data.entita){
      this.visHotspot=true;
    }
    if (this.data.sede){
      this.visUnits=true;
    }
    
    this.formHotspots = new FormGroup({
      'idHotspotNethspot': new FormControl(0),
    })
    this.formUnits = new FormGroup({
      'idUnitNethspot': new FormControl(0),
    })
    this.popolaCombo();
  }

  popolaCombo() {
    if (this.visHotspot) {
      this.nethHotspotService.GetNetHotspots().subscribe(
        response => {
          this.hotspots = response;
          this.fHotspots = this.hotspots.slice();
        }
      )
    }
    if (this.visUnits) {
      this.nethUnitsService.GetNethUnitsList().subscribe(
        response => {
          this.units = response;
          this.fUnits = this.units.slice();
        }
      )
    }
  }

  salvaEntita(dati:FormGroup){
    let entita:Entita=dati.value;
    let paramArray:PatchMethod[]=[];
    paramArray.push( new PatchMethod(entita.idHotspotNethspot.toString(),"/idHotspotNethspot","replace"));  
    this.entitaService.UpdEntita(this.data.entita.id,paramArray).subscribe(
      response=>{
        this.dialogRef.close({event:"OK"});
      }
    )
  }

  salvaSede(dati:FormGroup){
    let sede:Sedi=dati.value;
    let paramArray:PatchMethod[]=[];
    paramArray.push( new PatchMethod(sede.idUnitNethspot.toString(),"/idUnitNethspot","replace"));  
    this.sediService.UpdSede(this.data.sede.id,paramArray).subscribe(
      response=>{
        this.dialogRef.close({event:"OK"});
      }
    )
  }

}
