<h2 mat-dialog-title> Ruoli per {{ user.userName }} </h2>
<mat-dialog-content style="height: 120px;">

            <mat-slide-toggle class="example-margin" [(ngModel)]="isSuperAdmin" [checked]="isSuperAdmin" [disabled]="superAdminDisabled">SuperAdmin</mat-slide-toggle><br>
            <mat-slide-toggle class="example-margin" [(ngModel)]="isAdmin" [checked]="isAdmin" [disabled]="adminDisabled">Admin</mat-slide-toggle><br>
            <mat-slide-toggle class="example-margin" [(ngModel)]="isModerator" [checked]="isModerator" [disabled]="moderatorDisabled">Moderator</mat-slide-toggle><br>
            <mat-slide-toggle class="example-margin" [(ngModel)]="isMember" [checked]="isMember">Member</mat-slide-toggle><br>
   
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="salva()">Ok</button> 
</mat-dialog-actions>