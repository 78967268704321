<!-- <section class="container" fxLayout="row" fxLayoutGap="20px">
    <mat-card class="mat-elevation-z20" fxFlex="50" >
      <mat-card-title class="text-center">Effettua la login come gestione</mat-card-title>
      <mat-card-content>
        <form [formGroup]="f" (ngSubmit)="f.valid && login()">
          <p>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput id="userName" formControlName="userName">
              <mat-hint align="end">Username di accesso</mat-hint>
              <mat-hint *ngIf="userName.touched && userName.errors?.required" style="color: red;">La username è obbligatoria</mat-hint>
              <mat-hint *ngIf="userName.touched && userName.errors?.email" style="color:red">Inserire una mail corretta</mat-hint>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input matInput id="password" type="password" formControlName="password">
              <mat-hint align="end">Password di accesso</mat-hint>
              <mat-hint *ngIf="password.touched && password.errors?.required" style="color: red;">La password è obbligatoria</mat-hint>
            </mat-form-field>
          </p>                  
          <p>
            <button mat-flat-button color="primary" [disabled]="!f.valid" type="submit">Connetti</button>
          </p>          
        </form>
      </mat-card-content>
    </mat-card>
    <mat-card class="mat-elevation-z20" fxFlex="50">
      <mat-card-title class="text-center">Effettua la login come utente</mat-card-title>
      <mat-card-content>
        <form [formGroup]="fUtente" (ngSubmit)="fUtente.valid && loginUtente()">
          <p>
            <mat-form-field>
              <mat-label>Voucher</mat-label>
              <input matInput id="voucher" formControlName="voucher">
              <mat-hint align="end">Voucher</mat-hint>
              <mat-hint *ngIf="voucher.touched && voucher.errors?.required" style="color: red;">Il voucher è obbligatorio</mat-hint>
            </mat-form-field>
          </p>          
          <p>
            <button mat-flat-button color="primary" [disabled]="!fUtente.valid" type="submit">Connetti</button>
          </p>          
        </form>
      </mat-card-content>
    </mat-card>
  </section> -->
  <div  class="wave__container">

    <section class="col-lg-6" style="text-align: -webkit-center; margin-top: 5%; ">
     
      <div class="col-lg-6 ">
      <div class="container left"><input type="radio" name="tab" id="signin" checked="checked" /><input type="radio" name="tab" id="register" />
        <img src="/assets/img/edu-wifi-logo-w.png" class="img-fluid img" style="align-self: center;" />
        <div class="pages">
            <div class="page">
              <form [formGroup]="f" (ngSubmit)="f.valid && login()">
                <p class="responsive-label">
                  <mat-form-field >
                    <mat-label >Email</mat-label>
                    <input matInput id="userName" formControlName="userName">
                    <mat-hint align="end">Username di accesso</mat-hint>
                    <mat-hint *ngIf="userName.touched && userName.errors?.required" style="color: red;">La username è obbligatoria</mat-hint>
                    <mat-hint *ngIf="userName.touched && userName.errors?.email" style="color:red">Inserire una mail corretta</mat-hint>
                  </mat-form-field>
                </p>
                <p class="responsive-label">
                  <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput id="password" type="password" formControlName="password">
                    <mat-hint align="end">Password di accesso</mat-hint>
                    <mat-hint *ngIf="password.touched && password.errors?.required" style="color: red;">La password è obbligatoria</mat-hint>
                  </mat-form-field>
                </p>                  
                <p>
                  <button mat-flat-button color="primary" style="font-size: 1.8vh; margin-top: 15px;" [disabled]="!f.valid" type="submit">Connetti</button>
                </p>          
              </form>
            </div>
            <div class="page signup">
              <form [formGroup]="fUtente" (ngSubmit)="fUtente.valid && loginUtente()">
                <p class="responsive-label">
                  <mat-form-field>
                    <mat-label>Voucher</mat-label>
                    <input matInput id="voucher" formControlName="voucher">
                    <mat-hint align="end">Voucher</mat-hint>
                    <mat-hint *ngIf="voucher.touched && voucher.errors?.required" style="color: red;">Il voucher è obbligatorio</mat-hint>
                  </mat-form-field>
                </p>          
                <p >
                  <button mat-flat-button color="primary" [disabled]="!fUtente.valid" type="submit">Connetti</button>
                </p>          
              </form>
            </div>
        </div>
        <div class="tabs"><label class="tab" for="signin">
                <div class="text">Credenziali</div>
            </label><label class="tab" for="register">
                <div class="text">Voucher</div>
            </label></div>
    </div>
    </div>
    <div class="wave"></div>
    </section>
    
    
    </div>