import { Entita } from './../../_models/entita';
import { EntitaService } from './../../_services/entita.service';
import { NethHotspotsService } from './../../_services/nethHotspots.service';
import { UtentiService } from './../../_services/utenti.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utenti } from 'src/app/_models/utenti';
import { NethHotspots } from 'src/app/_models/_nethspotModels/nethHotspots';
import { SharedFunction } from 'src/app/_function/shared-functions';
import { PatchMethod } from 'src/app/_models/patchMethod';

@Component({
  selector: 'app-register-utente-dialog',
  templateUrl: './register-utente-dialog.component.html',
  styleUrls: ['./register-utente-dialog.component.scss']
})
export class RegisterUtenteDialogComponent implements OnInit {
  registrazioneForm: FormGroup;
  validationErrors: string[] = [];
  hidePassword1 = true;

  entita: Entita[];
  fEntita;
  isModifica: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data, private utentiService: UtentiService, private dialogRef: MatDialogRef<RegisterUtenteDialogComponent>, private entitaService: EntitaService, private sharedFunction: SharedFunction) { }

  ngOnInit(): void {
    this.registrazioneForm = new FormGroup({
      'nome': new FormControl('', [Validators.required]),
      'cognome': new FormControl('', [Validators.required]),
      'creaVoucher': new FormControl(true),
      'idEntita': new FormControl(0),
      'auto_login': new FormControl(false),
      'bandwidth_down': new FormControl(0),
      'bandwidth_up': new FormControl(0),
      'max_traffic': new FormControl(0),
      'max_time': new FormControl(0),
      'time': new FormControl("expiration"),
      'duration': new FormControl(0),
      'expiration': new FormControl(0),
      'type': new FormControl("auth"),
      'dataAl': new FormControl(this.sharedFunction.aggiungiGiorni(new Date(), 7)),
    })
    this.popolaCombo();

    if (this.data) {
      this.isModifica = true;
      console.log(this.data);

      this.registrazioneForm.controls['nome'].setValue(this.data.utente.nome)
      this.registrazioneForm.controls['cognome'].setValue(this.data.utente.cognome)
      this.registrazioneForm.controls['creaVoucher'].setValue(this.data.utente.creaVoucher)
      this.registrazioneForm.controls['auto_login'].setValue(this.data.utente.auto_login)
      this.registrazioneForm.controls['idEntita'].setValue(this.data.utente.idEntita)

      this.registrazioneForm.controls['bandwidth_down'].setValue(this.data.utente.bandwidth_down)
      this.registrazioneForm.controls['bandwidth_up'].setValue(this.data.utente.bandwidth_up)
      this.registrazioneForm.controls['max_traffic'].setValue(this.data.utente.max_traffic)
      this.registrazioneForm.controls['max_time'].setValue(this.data.utente.max_time)
      this.registrazioneForm.controls['dataAl'].setValue(this.data.utente.scadenza_voucher)
    }
  }

  popolaCombo() {
    this.entitaService.GetEntitaList().subscribe(
      response => {
        this.entita = response;
        this.fEntita = this.entita.slice();

        //Se c'è solo un'entità seleziono subito quella
        if (this.entita.length = 1) {
          this.registrazioneForm.controls['idEntita'].setValue(this.entita[0].id);

        }
      }
    );
  }

  get voucher() {
    return this.registrazioneForm.get('voucher');
  }

  get nome() {
    return this.registrazioneForm.get('nome');
  }

  get cognome() {
    return this.registrazioneForm.get('cognome');
  }

  salva(dati: FormGroup) {
    let utente: Utenti;
    utente = dati.value;

    //Recupero l'id dell'hotspot di Nethspot
    let tmpHotspot = this.entita.find(e => e.id == dati.value.idEntita);
    utente.hotspot_id = tmpHotspot.idHotspotNethspot;

    let tmpDay = this.sharedFunction.ritornaNumeroGiorniDifferenzaDaOggi(new Date(dati.value.dataAl));
    //Valorizzo i giorni da inserrie in expiration
    utente.expiration = tmpDay;
    utente.scadenza_voucher = dati.value.dataAl;

    if (this.isModifica) {
      console.log(utente);
      utente.id = this.data.utente.id;

      let paramArray: PatchMethod[] = [];
      paramArray.push(new PatchMethod(utente.cognome, "/cognome", "replace"));
      paramArray.push(new PatchMethod(utente.nome, "/nome", "replace"));
      paramArray.push(new PatchMethod(utente.bandwidth_down.toString(), "/bandwidth_down", "replace"));
      paramArray.push(new PatchMethod(utente.bandwidth_up.toString(), "/bandwidth_up", "replace"));
      paramArray.push(new PatchMethod(utente.max_traffic.toString(), "/max_traffic", "replace"));
      paramArray.push(new PatchMethod(utente.max_time.toString(), "/max_time", "replace"));
      paramArray.push(new PatchMethod(new Date(utente.scadenza_voucher).toISOString(), "/scadenza_voucher", "replace"));
      paramArray.push(new PatchMethod(utente.expiration.toString(), "/expiration", "replace"));

      this.utentiService.Update(utente.id, paramArray).subscribe(
        response => {
          this.dialogRef.close({ event: "OK" });
        }
      )

    } else {
      this.utentiService.register(utente).subscribe(
        response => {
          this.dialogRef.close({ event: "OK" });
        }

      )
    }





  }


}
