<h2 mat-dialog-title> Nuovo utente </h2>
<mat-dialog-content style="color: black !important; width: 50vh!important;">
  <form *ngIf="registrazioneForm" [formGroup]="registrazioneForm"
    (ngSubmit)="registrazioneForm.valid && salva(registrazioneForm)" id="registrazioneForm">

    <p>
      <mat-form-field>
        <mat-label style="color: black !important;">Cognome</mat-label>
        <input matInput style="color: black !important;" id="cognome" formControlName="cognome">
        <mat-hint *ngIf="cognome.touched && cognome.errors?.required" style="color: red;">Il cognome è obbligatorio
        </mat-hint>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <mat-label style="color: black !important;">Nome</mat-label>
        <input style="color: black !important;" matInput id="nome" formControlName="nome">
        <mat-hint *ngIf="nome.touched && nome.errors?.required" style="color: red;">IL nome è obbligatorio</mat-hint>
      </mat-form-field>
    </p>
    <p>
      <mat-slide-toggle id="creaVoucher" formControlName="creaVoucher">Crea voucher</mat-slide-toggle><br>
      <mat-slide-toggle class="my-3" id="auto_login" formControlName="auto_login">Autologin</mat-slide-toggle><br><br>
    </p>
    <!-- <p>
            <mat-form-field>
                <mat-label>Voucher</mat-label>
                <input matInput id="voucher" formControlName="voucher">
                <mat-hint *ngIf="voucher.touched && voucher.errors?.required" style="color: red;">Il voucher è obbligatorio</mat-hint>                
            </mat-form-field>
        </p> -->
    <p>
      <mat-form-field appearance="fill" >
        <mat-label style="color: #fff !important;">Entita</mat-label>
        <mat-select formControlName="idEntita" #sEntita>
          <mat-select-filter [placeholder]="'Cerca'" *ngIf="sEntita.focused" [displayMember]="'nome'"
            [array]="entita" (filteredReturn)="fEntita =$event"></mat-select-filter>
          <mat-option value=0>Tutti</mat-option>
          <mat-option [value]="e.id" *ngFor="let e of fEntita">
            {{e.nome}}</mat-option>
        </mat-select>
      </mat-form-field>
    </p>

    <h3 style="color: #0cb5f7;" class="pt-3"><br><br>Banda massima in download per utente</h3>
    <mat-form-field appearance="fill">
      <mat-label style="color: black !important;">Kbps</mat-label>
      <input style="color: black !important;" matInput id="bandwidth_down" formControlName="bandwidth_down">
    </mat-form-field>
    <h3 style="color: #0cb5f7;">Banda massima in upload per utente</h3>
    <mat-form-field appearance="fill">
      <mat-label style="color: black !important;">Kbps (0 = illimitato)</mat-label>
      <input style="color: black !important;" matInput id="bandwidth_up" formControlName="bandwidth_up">
    </mat-form-field>
    <h3 style="color: #0cb5f7;">Durata massima giornaliera</h3>
    <mat-form-field appearance="fill">
      <mat-label style="color: black !important;">minuti (0 = illimitato)</mat-label>
      <input style="color: black !important;" matInput id="max_time" formControlName="max_time">
    </mat-form-field>
    <h3 style="color: #0cb5f7;">Traffico massimo giornaliero</h3>
    <mat-form-field appearance="fill">
      <mat-label style="color: black !important;">MB (0 = illimitato)</mat-label>
      <input style="color: black !important;" matInput id="max_traffic" formControlName="max_traffic">
    </mat-form-field>
    <hr>
    <h3 style="color: #0cb5f7;">Seleziona una data di scadenza</h3>
    <mat-form-field appearance="fill" class="example-form-field">
      <mat-label>Choose a date</mat-label>
      <input style="color: black !important;" matInput [matDatepicker]="datepicker" formControlName="dataAl">
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
  </form>
  <p style="margin-top: 20px;">
    <button mat-flat-button color="primary" id=btnSalva form="registrazioneForm" [disabled]="!registrazioneForm.valid"
      type="submit">
      <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
    </button>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- <button mat-button mat-dialog-close="OK">Ok</button>  -->
</mat-dialog-actions>