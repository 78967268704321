import { AdminService } from './../../_services/admin.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-roles-dialog',
  templateUrl: './roles-dialog.component.html',
  styleUrls: ['./roles-dialog.component.scss']
})
export class RolesDialogComponent implements OnInit {
  user:User;
  roles:any[];

  isSuperAdmin:boolean=false;
  isAdmin:boolean=false;
  isModerator:boolean=false;
  isMember:boolean=false;

  superAdminDisabled:boolean=true;
  adminDisabled:boolean=true;
  moderatorDisabled:boolean=true;

  constructor(@Inject(MAT_DIALOG_DATA) private data, private dialogRef: MatDialogRef<RolesDialogComponent>, private adminService:AdminService,private accountService: AccountService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.user=this.data.user;
    this.roles = this.data.roles;    
    let tmpSuperAdmin = this.roles.find(x=>x.value=="SuperAdmin");
    this.isSuperAdmin=tmpSuperAdmin.checked;
    let tmpAdmin = this.roles.find(x=>x.value=="Admin");
    this.isAdmin=tmpAdmin.checked;
    let tmpModerator = this.roles.find(x=>x.value=="Moderator");
    this.isModerator=tmpModerator.checked;
    let tmpMember = this.roles.find(x=>x.value=="Member");
    this.isMember=tmpMember.checked;
    this.disabilitaCheckbox();
  }

  disabilitaCheckbox(){
    if(this.accountService.isSuperAdmin()){
      this.superAdminDisabled=false;
      this.adminDisabled=false;
      this.moderatorDisabled=false;
    }
    if (this.accountService.isAdmin()){
      this.moderatorDisabled=false;
    }    
  }

  salva(){
    let arrayRoles:string[]=[];
    if (this.isSuperAdmin){
      arrayRoles.push("SuperAdmin")
    }
    if (this.isAdmin){
      arrayRoles.push("Admin")
    }
    if (this.isModerator){
      arrayRoles.push("Moderator")
    }
    if (this.isMember){
      arrayRoles.push("Member")
    }    
    
    //Salvo a database
    this.adminService.updateUserRoles(this.user.userName,arrayRoles).subscribe(
      response=>{
        this.dialogRef.close({event:"OK"});
      }
    )
    
      
  }



  

}
