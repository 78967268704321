import { UtentiService } from './../_services/utenti.service';
import { AccountService } from './../_services/account.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UtentiGuard implements CanActivate {

  constructor(private utentitService: UtentiService, private router: Router) { }

  canActivate() {
    if(this.utentitService.isAuth()){
      return true;
    }else{
      this.router.navigate(['/login'])
    }   
  }

}
