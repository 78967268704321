import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ButtonsModule } from 'ngx-bootstrap/buttons'
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      positionClass:'toast-bottom-center'
    }),    
    PaginationModule.forRoot(),
    ButtonsModule.forRoot(),    
    ModalModule.forRoot()
  ],
  exports:[
    CommonModule,
    ToastrModule,    
    PaginationModule,
    ButtonsModule,    
    ModalModule
  ]
})
export class SharedModule { }
