<ng-container>
    <button (click)="get500Error()" class="btn btn-outline-primary mr-3">Test 500 error</button>
    <button (click)="get400Error()" class="btn btn-outline-primary mr-3">Test 400 error</button>
    <button (click)="get401Error()" class="btn btn-outline-primary mr-3">Test 401 error</button>
    <button (click)="get404Error()" class="btn btn-outline-primary mr-3">Test 404 error</button>
    <button (click)="get400ValidationError()" class="btn btn-outline-primary mr-3">Test 400 validation error</button>

    <div class="row mt-5" *ngIf="validationsErrors.length>0">
        <ul class="text-danger">
            <li *ngFor="let error of validationsErrors">
                {{error}}
            </li>
        </ul>
    </div>
</ng-container>