import { ClientiService } from './../../_services/clienti.Service';
import { Cliente } from './../../_models/clienti';
import { AdminService } from './../../_services/admin.service';
import { User } from './../../_models/user';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountService } from 'src/app/_services/account.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-register-user-dialog',
  templateUrl: './register-user-dialog.component.html',
  styleUrls: ['./register-user-dialog.component.scss']
})
export class RegisterUserDialogComponent implements OnInit {
  registerForm: FormGroup;
  validationErrors: string[] = [];
  hidePassword1 = true;

  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  isModerator: boolean = false;
  isMember: boolean = false;

  superAdminDisabled: boolean = true;
  adminDisabled: boolean = true;
  moderatorDisabled: boolean = true;

  clienti: Cliente[];
  fClienti;

  constructor(private accountService: AccountService, private dialogRef: MatDialogRef<RegisterUserDialogComponent>, private adminService: AdminService, private clientiService: ClientiService) { }

  ngOnInit(): void {
    this.popolaCombo();
    this.registerForm = new FormGroup({
      'userName': new FormControl('', [Validators.required, Validators.email]),
      'password': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'nome': new FormControl('', [Validators.required]),
      'cognome': new FormControl('', [Validators.required]),
      'idCliente': new FormControl(0, [Validators.min(1)])
    })
    this.disabilitaCheckbox();
  }

  get userName() {
    return this.registerForm.get('userName');
  }

  get password() {
    return this.registerForm.get('password');
  }

  get ripetiPassword() {
    return this.registerForm.get('ripetiPassword');
  }

  get nome() {
    return this.registerForm.get('nome');
  }

  get cognome() {
    return this.registerForm.get('cognome');
  }

  get idCliente(){
    return this.registerForm.get("idCliente");
  }

  popolaCombo() {
    this.clientiService.GetClientiList().subscribe(
      response => {
        this.clienti = response;
        this.fClienti = this.clienti.slice();
      }
    )
  }

  disabilitaCheckbox() {
    if (this.accountService.isSuperAdmin()) {
      this.superAdminDisabled = false;
      this.adminDisabled = false;
      this.moderatorDisabled = false;
    }
    if (this.accountService.isAdmin()) {
      this.moderatorDisabled = false;
    }
  }

  salva(dati: FormGroup) {
    let user: User = dati.value;
    this.accountService.register(user).subscribe(
      response => {
        //Salvo successivamente i ruoli
        let arrayRoles: string[] = [];
        if (this.isSuperAdmin) {
          arrayRoles.push("SuperAdmin")
        }
        if (this.isAdmin) {
          arrayRoles.push("Admin")
        }
        if (this.isModerator) {
          arrayRoles.push("Moderator")
        }
        if (this.isMember) {
          arrayRoles.push("Member")
        }
        this.adminService.updateUserRoles(user.userName, arrayRoles).subscribe(
          response => {
            this.dialogRef.close({ event: "OK" });
          }
        )

      }
    )
  }

}
