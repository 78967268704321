import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})


export class SharedFunction {

    constructor() { }

    aggiungiGiorni(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    ritornaNumeroGiorniDifferenzaDaOggi(dataAl:Date){
        let oggi:Date=new Date();
        // let tmpDateGetTime=new Date(dataAl.toISOString()).getTime();
        let tmpDateGetTime=new Date(dataAl.toDateString()).getTime();
        let millisecondi:number=tmpDateGetTime - oggi.getTime();
        return Math.ceil(millisecondi / (1000 * 60 * 60 * 24));
    }   

    ritornaTimeStamp(){
        //Ritorno il timestamp in millisecondi in formato unix
        let timestamp:number=Math.floor(Date.now());       
        return timestamp;
    }
    
}