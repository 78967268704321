import { PatchMethod } from 'src/app/_models/patchMethod';
import { EntitaService } from 'src/app/_services/entita.service';
import { Entita } from 'src/app/_models/entita';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleAccount } from './../../../_models/googleAccount';
import { ImportService } from 'src/app/_services/import.service';
import { googleToken } from './../../../_models/googleToken';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { SharedFunction } from 'src/app/_function/shared-functions';
import { DatiGoogleIMportazioneNethspot, GoogleImportazioneNethspot } from 'src/app/_models/googleImportazioneNethspot';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

export class CartelleFiltro{
  constructor(
    public testo:string    
  ){    
  }
}

@Component({
  selector: 'app-import-google',
  templateUrl: './import-google.component.html',
  styleUrls: ['./import-google.component.scss']
})
export class ImportGoogleComponent implements OnInit {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  columnsToDisplay: string[] = ['select', 'orgUnitPath', 'familyName', 'givenName', 'primaryEmail'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource<GoogleAccount>();
  selection = new SelectionModel<GoogleAccount>(true, []);

  user: SocialUser;
  loggedIn: boolean;

  googleAccount: GoogleAccount[];
  googleAccountFiltrati: GoogleAccount[];
  googleCreateUser: GoogleAccount[] = [];

  registrazioneForm: FormGroup;
  entita: Entita[];
  fEntita;

  entitaSelezionata: Entita;

  filtriForm: FormGroup;
  cartelleFiltro:CartelleFiltro[]=[];
  fCartelleFiltro;

  token: googleToken; 
  // bottoniFiltro:BottoneFiltro[];

  constructor(private authService: SocialAuthService, private importService: ImportService, private sharedFunction: SharedFunction,
    private entitaService: EntitaService) { }



  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });

    this.registrazioneForm = new FormGroup({
      'idEntita': new FormControl(0, Validators.min(1)),
      'auto_login': new FormControl(false),
      'bandwidth_down': new FormControl(0),
      'bandwidth_up': new FormControl(0),
      'max_traffic': new FormControl(0),
      'max_time': new FormControl(0),
      'time': new FormControl("expiration"),
      'duration': new FormControl(0),
      'expiration': new FormControl(0),
      'type': new FormControl("auth"),
      'dataAl': new FormControl(this.sharedFunction.aggiungiGiorni(new Date(), 7)),
    })

    this.filtriForm = new FormGroup({
      'testo': new FormControl(''),
      'cartella':new FormControl('')
    })

    this.popolaCombo();

  }

  popolaCombo() {
    this.entitaService.GetEntitaList().subscribe(
      response => {
        this.entita = response;
        this.fEntita = this.entita.slice();
        if (this.entita.length == 1) {
          this.registrazioneForm.controls['idEntita'].setValue(this.entita[0].id);
          this.popolaDatiEntita(this.entita[0].id);
        }
      }
    )
  }

  popolaDatiEntita(idEnt: number) {
    let ent:Entita=this.entita.find(f=>f.id==idEnt);
    this.registrazioneForm.controls['dataAl'].setValue(ent.ultimaDataScadenzaVoucher);
    this.registrazioneForm.controls['auto_login'].setValue(ent.ultimo_auto_login);
    this.registrazioneForm.controls['bandwidth_down'].setValue(ent.ultimo_bandwidth_down);
    this.registrazioneForm.controls['bandwidth_up'].setValue(ent.ultimo_bandwidth_up);
    this.registrazioneForm.controls['max_traffic'].setValue(ent.ultimo_max_traffic);
    this.registrazioneForm.controls['max_time'].setValue(ent.ultimo_max_time);
    this.entitaSelezionata = ent;

    //Messo momentaneamente per verifica errore su date
    let tmpExpiration = this.sharedFunction.ritornaNumeroGiorniDifferenzaDaOggi(new Date(this.registrazioneForm.controls['dataAl'].value));
  }

  signInWithGoogle(): void {

    //Mi loggo in Google
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);


    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
        .then(d => console.log(d))
        .catch(error => { console.log(error);});
  }

  signOut(): void {
    this.authService.signOut();
    this.googleAccount = [];
    this.googleCreateUser = [];
  }

  refreshToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  sendTokenAndGetUsersGoogle() {
    this.token = this.user.response;
    var date = new Date(this.token.first_issued_at);
    var result = date.toISOString()
    this.token.IssuedUtc = result;
    this.popolaGoogleAccount();
  }

  filtra(dati: FormGroup) {
    this.popolaGoogleAccountFiltrati(dati);
  }

  pulisciFiltri(){
    this.filtriForm.controls['testo'].setValue('');
    this.filtriForm.controls['cartella'].setValue('');
    this.popolaGoogleAccountFiltrati(this.filtriForm);
  }

  popolaGoogleAccountFiltrati(dati: FormGroup) {
    this.googleAccountFiltrati = this.googleAccount;

    //Filtro gli account
    if (dati.value.testo != "") {
      this.googleAccountFiltrati = this.googleAccount.filter(f => f.familyName.toLowerCase().includes(dati.value.testo.toLowerCase())  
      || f.givenName.toLocaleLowerCase().includes(dati.value.testo.toLowerCase()) 
      || f.orgUnitPath.toLocaleLowerCase().includes(dati.value.testo.toLowerCase())
      || f.primaryEmail.toLocaleLowerCase().includes(dati.value.testo.toLowerCase()));
    }
    if (dati.value.cartella !=""){
      this.googleAccountFiltrati =this.googleAccountFiltrati.filter(f=>f.orgUnitPath.toLowerCase()==dati.value.cartella.toLowerCase());
    }
    
    this.abbinaDatiAMatSource();

  }

  abbinaDatiAMatSource() {
    this.dataSource = null;
    this.dataSource = new MatTableDataSource<GoogleAccount>(this.googleAccountFiltrati);
    this.googleAccountFiltrati = this.googleAccountFiltrati.sort((a, b) => (a.familyName > b.familyName ? -1 : 1));

    this.dataSource.sort = this.sort;

    this.dataSource.paginator = this.paginator;

    //Pulisco la selezione quando recupero di nuovo gli utenti
    this.selection = new SelectionModel<GoogleAccount>(true, []);
  }

  popolaGoogleAccount() {

    this.importService.Prova(this.token).subscribe(
      response => {
        //Recupero la lista diegli utenti
        this.RecuperaListaUtentiGoogle();
      }
    )
  }

  RecuperaListaUtentiGoogle(){
    this.importService.GetUsersGoogle().subscribe(
      response => {
        this.googleAccount = response;
        this.googleAccountFiltrati = this.googleAccount;
        this.abbinaDatiAMatSource();
        this.creaGraficaFiltri();


      }
    )
  }

  creaGraficaFiltri() {
    let arrayGruppi: string[] = [];
    this.googleAccount.forEach(function (account) {
      let tmpGruppo: string = account.orgUnitPath;
      let presente = arrayGruppi.filter(f => f == tmpGruppo);
      if (presente.length == 0) {
        arrayGruppi.push(tmpGruppo);
      }
    })

    let tmpCartelle:CartelleFiltro[]=[];
    arrayGruppi.forEach(function(x){
      tmpCartelle.push(new CartelleFiltro(x));
    })
    this.cartelleFiltro=tmpCartelle;
    this.fCartelleFiltro = this.cartelleFiltro.slice();

    // let bottoni:BottoneFiltro[]=[]
    // arrayGruppi.forEach(function(x){
    //   bottoni.push(new BottoneFiltro(x,x,true,''));
    // })
    // this.bottoniFiltro=bottoni;    
    

  }

  createUserSV(dati: FormGroup) {
   
    this.selection.selected.forEach(element => {
      this.googleCreateUser.push(element);
    });

    
    

    // Calcolo l'expiration
    let tmpExpiration = this.sharedFunction.ritornaNumeroGiorniDifferenzaDaOggi(new Date(dati.value.dataAl));

    let param: DatiGoogleIMportazioneNethspot = new DatiGoogleIMportazioneNethspot(dati.value.duration, tmpExpiration, dati.value.auto_login, this.entitaSelezionata.idHotspotNethspot, dati.value.bandwidth_down, dati.value.bandwidth_up, dati.value.max_traffic, dati.value.max_tima, dati.value.time, dati.value.type, 5,dati.value.dataAl)
    let imp: GoogleImportazioneNethspot = new GoogleImportazioneNethspot(this.googleCreateUser, param);

    this.importService.CreaUtentiSuperVise(imp).subscribe(
      response => {
        // Salvo i parametri dei voucher nell'entità
        let datiTemp = dati.value;
        let paramArray: PatchMethod[] = [];
        paramArray.push(new PatchMethod(datiTemp.auto_login, "/Ultimo_auto_login", "replace"))
        paramArray.push(new PatchMethod(datiTemp.bandwidth_down, "/Ultimo_bandwidth_down", "replace"))
        paramArray.push(new PatchMethod(datiTemp.bandwidth_up, "/Ultimo_bandwidth_up", "replace"))
        paramArray.push(new PatchMethod(datiTemp.max_time, "/Ultimo_max_time", "replace"))
        paramArray.push(new PatchMethod(datiTemp.max_traffic, "/Ultimo_max_traffic", "replace"))
        paramArray.push(new PatchMethod(new Date(datiTemp.dataAl).toDateString(), "/UltimaDataScadenzaVoucher", "replace"))


        this.entitaService.UpdEntita(this.entitaSelezionata.id, paramArray).subscribe(
          response => {
            //Recupero lista utenti aggionata e pulisco tutti            
            this.popolaGoogleAccount();
            this.pulisciFiltri();
            this.selection.clear();
            this.googleCreateUser=[];
          }
        )


      }
    )
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: GoogleAccount): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}