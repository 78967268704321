<h2 mat-dialog-title> Sedi per {{ user.userName }} </h2>
<mat-dialog-content style="height: 120px;">
    <ng-container *ngFor="let sede of sedi">
        <mat-slide-toggle class="example-margin" id={{sede.id}} (change)="selectSede(sede.id,$event.checked)"
            [checked]="toggleCheckBox(sede.id)">
            {{ sede.entita.nome}} -  {{sede.nome}}
        </mat-slide-toggle><br>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="salva()">Ok</button> 
</mat-dialog-actions>