import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogClass } from 'src/app/_models/dialogClass';

@Component({
  selector: 'app-si-no-dialog',
  templateUrl: './si-no-dialog.component.html',
  styleUrls: ['./si-no-dialog.component.scss']
})
export class SiNoDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:DialogClass) { }

  ngOnInit(): void { 

  }

}
