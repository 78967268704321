import { NethHotspotsPreference } from './../_models/_nethspotModels/nethHotspotPreference';
import { ApiMsg } from './../_models/apiMessage';
import { NethHotspots } from './../_models/_nethspotModels/nethHotspots';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn:"root"
})
export class NethHotspotsService{
    baseUrl = environment.apiUrl;

    constructor(private http:HttpClient){}

    GetNetHotspots(){
        return this.http.get<NethHotspots[]>(this.baseUrl + "nethhotspots");
    }

    InsertHotspot(nethHotspot:NethHotspots){
        return this.http.post<ApiMsg>(this.baseUrl + "nethhotspots/insert",nethHotspot);
    }

    UpdateHotspot(nethHotspot:NethHotspots ){
        return this.http.put<ApiMsg>(this.baseUrl + "nethhotspots/edit",nethHotspot);
    }    

    DeleteHotspot(id:number){
        return this.http.delete<ApiMsg>(this.baseUrl + "nethhotspots/delete/" + id)
    }

    GetHotspotPreference(id:number){
        return this.http.get<NethHotspotsPreference>(this.baseUrl + "nethhotspots/preference/" + id);
    }

    UpdateHotspotPreference(nethHotSpotsPreference:NethHotspotsPreference){
        return this.http.put<ApiMsg>(this.baseUrl + "nethhotspots/edit-preference/" + nethHotSpotsPreference.idHotspot,nethHotSpotsPreference);
    }
}